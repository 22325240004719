<template>
	<title>{{title}}</title>
	<div class="h-100">
		<router-view v-if="brandLoaded" />
	</div>
</template>
<script>
import { mapActions } from 'vuex'
import { getDirection } from '@/utils'

export default {
	name: 'App',
	data () {
		return {
			user: false,
			brandLoaded: false,
			title: '',
		}
	},
	created () {
		this.getBrandFile();
		
		//get brand file then use the now set localstorage items for branding	
		if (localStorage.getItem('titleName')) {
			this.title = localStorage.getItem('titleName');
		}
		if (localStorage.getItem('themeColor')) {
			const favicon = document.getElementById("favicon");
			favicon.href = "/frontend/assets/img/" + localStorage.getItem('themeColor') + '.png';
		}
		localStorage.setItem('apiURL', process.env.VUE_APP_API_URL);
	},
	methods: {
		...mapActions(['logout']),
		
		//Function for getting brand file with subdomain
		getBrandFile () {
			this.req('GET', '/brand/getbrandfile/').then((response) => {
				if (response.status) {
					if(localStorage.getItem('titleName') != response.data.name){
						localStorage.setItem('titleName', response.data.name);
					}
					if(localStorage.getItem('themeColor') != response.data.brand_file){
						localStorage.setItem('themeColor', response.data.brand_file);
						setTimeout(() => {
							window.location.reload();
						}, 500);
					} else {
						this.brandLoaded = true;
					}
				} else {
					this.brandLoaded = true;
				}
			}, () => {
				this.brandLoaded = true;
			});
		}
	},
	beforeMount () {
		const direction = getDirection()
		if (direction.isRtl) {
			document.body.classList.add('rtl')
			document.dir = 'rtl'
			document.body.classList.remove('ltr')
		} else {
			document.body.classList.add('ltr')
			document.dir = 'ltr'
			document.body.classList.remove('rtl')
		}
	}
}
</script>
