<!--
 version 1.2.3
 - Backend for wizard
 version 1.2.2
 - Better backend filter, fix selectable process
 version 1.2.0
 - Add backend filter and inline edit
 version 1.1.6
 - Fix multiselect 
 - Add translation 
 - Add buttons on base of rights
 version 1.1.5
 - Fix currency box
 version 1.1.4
 - Add disabled to fields
 version 1.1.3
 - Add link in filter options
 version 1.1.2
 - Add different labels
 - Fix time conversion
 version 1.1.0
 - Add content style
 - Add customClass on button
 - Add wizard
 - Add labels
 version 0.5.1
 - Add min - max filter
 version 0.5.0
 - Update put CSV
 - Update button put CSV
-->
<template>
	<div>
		<div class="card" :class="panelClass" v-if="myTable && myTable['controller']" v-show="!myTable['hideTable'] && !showWizard">
			
			<div class="card-header">
				<span @click="myTable['tableFold'] = !myTable['tableFold']">{{ myTable['label'] ? myTable['label'] : (myTable['name'] ? myTable['name'] : '&nbsp;') }}</span>
				<i @click="myTable['tableFold'] = !myTable['tableFold']" class="crud-table-left crud-table-spacing-after">
					<font-awesome-icon icon="sort" />
				</i>
				<i @click="showFilters = !showFilters" class="crud-table-right crud-table-divide-before" :class="{'crud-table-btn-active': showFilters}">
					<font-awesome-icon icon="filter" />
				</i>
				<i @click="showSearch = !showSearch" class="crud-table-right crud-table-divide-before" :class="{'crud-table-btn-active': showSearch}">
					<font-awesome-icon icon="search" />
				</i>
				<input type="text" class="crud-table-right crud-table-search" v-model="myTable['globalSearch']" v-show="showSearch || globalSearch" />
				<i v-show="showCSV" @click="getCSV()" class="crud-table-right crud-table-divide-before">
					<font-awesome-icon icon="table" />
				</i>

				<span v-show="message" class="crud-table-message">{{ message }}</span>
			</div>
			<div class="card-body" v-show="myTable['description'] && !myTable['tableFold']">
				<p>{{ myTable['description'] }}</p>
			</div>
			<div class="crud-table-button-row" v-show="myTable['buttonRow'] && !myTable['tableFold']" v-if="!myTable['readOnly']">
				<div v-if="buttons">
					<button v-for="btn in headerButtons" :key="btn.id" class="crud-table-button-header" :class="btn.customClass ? btn.customClass : ''" @click="doBtn(btn, {})" v-show="btnWhere(btn, {})" type="button"><i aria-hidden="true"><font-awesome-icon :icon="btn.icon" /></i>&nbsp;{{ blnTranslation && btn.nameLabel ? $t(btn.nameLabel, { label: (this.myTable.label ? this.myTable.label : (this.myTable.name ? this.myTable.name : 'Row')) }) : btn.name }}</button>
				</div>
			</div>
			<div class="table-responsive" id="crud-table-no-more-tables" v-show="!myTable['tableFold']">
				<table class="table table-striped crud-table">
					<thead>
						<tr>
							<th class="crud-table-shrink" v-if="myTable['selectable']">
								<select class="fa" style="width: 50px; font-family: FontAwesome, sans-serif;" v-model="myTable['toSelectRows']" @change="myTable.selectRows(filterRows)">
									<option selected value="0">&nbsp;</option>
									<option v-bind:value="1">{{ blnTranslation ? $t('crud-table.deSelectFilteredRows') : myTable.labels.deSelectFilteredRows }}</option>
									<option v-bind:value="2">{{ blnTranslation ? $t('crud-table.selectFilteredRows') : myTable.labels.selectFilteredRows }}</option>
									<option v-bind:value="3">{{ blnTranslation ? $t('crud-table.deSelectAllRows') : myTable.labels.deSelectAllRows }}</option>
									<option v-bind:value="4">{{ blnTranslation ? $t('crud-table.selectAllRows') : myTable.labels.selectAllRows }}</option>
								</select>
							</th>
							<th class="crud-table-shrink" v-if="myTable['showRowCount']">#</th>
							<th v-for="field in getOrderedFields()" @click="setSortRows(field.name)" class="crud-table-th" :key="field.name">
								{{ toLabel(field.name) }}
								<span class="crud-table-sortorder" v-show="showSort(field.name)" :class="{reverse: showSortReverse(field.name)}"></span>
							</th>
							<th class="crud-table-shrink" id="crud-table-empty" v-if="buttons && !myTable['readOnly']">&nbsp;</th>
						</tr>
					</thead>
					<tbody v-if="myTable['data'] && myTable['data']['rows']">
						<tr v-show="showFilters" id="crud-table-filter-row">
							<td data-title="Selected" class="crud-table-shrink" v-if="myTable['selectable']">
								<select class="fa" style="width: 50px; font-family: FontAwesome, sans-serif;" v-model="rowFilter['sba_selected']" @change="onChangeFilter">
									<option selected value="">&nbsp;</option>
									<option value="false">{{ blnTranslation ? $t('crud-table.showNotSelectedRows') : myTable.labels.showNotSelectedRows }}</option>
									<option value="true">{{ blnTranslation ? $t('crud-table.showSelectedRows') : myTable.labels.showSelectedRows }}</option>
								</select>
							</td>
							<td data-title="#" class="crud-table-shrink" v-if="myTable['showRowCount']">
								&nbsp;
							</td>
							<td :data-title="toLabel(field.name)" v-for="field in getOrderedFields()" :key="field.name">
								<select v-if="getFieldType(field.name) == 'boolean'" v-model="rowFilter[field.name]" class="sba-full sba-options-full" @input="debounceFunction(() => { onChangeFilter(); })">
									<option selected value="">&nbsp;</option>
									<option value="false">{{ blnTranslation ? $t('crud-table.filterFalse') : myTable.labels.filterFalse }}</option>
									<option value="true">{{ blnTranslation ? $t('crud-table.filterTrue') : myTable.labels.filterTrue }}</option>
								</select>
								<input v-if="getFieldType(field.name) != 'boolean'" placeholder="Filter" type="text" class="sba-full" v-model="rowFilter[field.name]" @input="debounceFunction(() => { onChangeFilter(); })" />
								<input v-if="getFieldOption(field.name, 'minmax')" placeholder="Min." type="text" step="0.01" class="sba-half" v-model="rowFilterMin[field.name]" @input="debounceFunction(() => { onChangeFilter(); })" />
								<input v-if="getFieldOption(field.name, 'minmax')" placeholder="Max." type="text" step="0.01" class="sba-half" v-model="rowFilterMax[field.name]" @input="debounceFunction(() => { onChangeFilter(); })" />
							</td>
							<td v-if="buttons && !myTable.readOnly" id="crud-table-empty">&nbsp;</td>
						</tr>
						<!-- (filteredRows = limitBy(filterBy(filterBy(limitBy(sortedRows, myTable.limit), myTable['globalSearch']), getFilter), myTable['pageLimit'], (myTable['pageLimit'] * page))) -->
						<template v-for="(row, index) in filteredRows" :key="index">
							<tr :class="trLayout(row)">
								<td data-title="Selected" class="crud-table-shrink" v-if="myTable['selectable']">
									<input type="checkbox" class="sba-full-checkbox" v-model="row['sba_selected']" />
								</td>
								<td class="crud-table-shrink" data-title="#" v-if="myTable['showRowCount']">{{ (index+1)+(getPageIndex()) }}</td>
								<td :data-title="toLabel(field.name)" v-for="field in getOrderedFields()" :key="field.name" style="white-space: pre-line" :id="'field_' + field.name" :class="tdLayout(field.name, getFieldData(row, field.name))" @click="inlineEdit($event, row, field.name)">
									<span v-if="!isInlineField(field.name) || myTable['readOnly']" v-html="getFieldData(row, field.name)" :class="spanLayout(field.name, getFieldData(row, field.name))"></span>
									<template v-if="isInlineField(field.name) && (fieldDetail = myTable['fieldDetails'][field.name]) && !myTable['readOnly']">
										<div style="display:inline-block;position:relative;width:100%">
											<input v-if="fieldDetail['type']=='datetime' && !fieldDetail['inlineSearch']" :maxlength="fieldDetail.max_length" :readonly="fieldDetail.disabled" :class="getInlineClasses(field.name)" class="form-control form-control-sm sba-full" v-model="row[field.name]" type="datetime-local" @change="checkInlineRequired(row, field.name);changeFieldTrigger(field.name, row[field.name], 'change', false)" />
											<input v-if="fieldDetail['type']=='password' && !fieldDetail['inlineSearch']" :maxlength="fieldDetail.max_length" :readonly="fieldDetail.disabled" :class="getInlineClasses(field.name)" class="form-control form-control-sm sba-full" v-model="row[field.name]" type="password" @change="checkInlineRequired(row, field.name);changeFieldTrigger(field.name, row[field.name], 'change', false)" autocomplete="new-password" />
											<input v-if="fieldDetail['type']=='number' && !fieldDetail['inlineSearch']" :maxlength="fieldDetail.max_length" :readonly="fieldDetail.disabled" :class="getInlineClasses(field.name)" class="form-control form-control-sm sba-full" v-model="row[field.name]" type="number" @change="checkInlineRequired(row, field.name);changeFieldTrigger(field.name, row[field.name], 'change', false)" :min="fieldDetail.minimal_amount" step="any"  />
											<input v-if="fieldDetail['type']=='time' && !fieldDetail['inlineSearch']" :maxlength="fieldDetail.max_length" :readonly="fieldDetail.disabled" :class="getInlineClasses(field.name)" class="form-control form-control-sm sba-full" v-model="row[field.name]" type="time" @change="checkInlineRequired(row, field.name);changeFieldTrigger(field.name, row[field.name], 'change', false)" />
											<input v-if="(fieldDetail['type']=='text' || !fieldDetail['type']) && !fieldDetail['inlineSearch']" :maxlength="fieldDetail.max_length" :readonly="fieldDetail.disabled" :class="getInlineClasses(field.name)" class="form-control form-control-sm sba-full" v-model="row[field.name]" type="text" @input="debounceFunction(() => { checkInlineRequired(row, field.name);changeFieldTrigger(field.name, row[field.name], 'change', false) });" />
											<!-- inline search field -->
											<input v-if="fieldDetail['inlineSearch']" :maxlength="fieldDetail.max_length" :readonly="fieldDetail.disabled" :class="getInlineClasses(field.name)" class="form-control form-control-sm sba-full" v-model="row[field.name]" :type="fieldDetail['type']" @input="debounceFunction(() => { checkInlineSearch($event, row, field.name);changeFieldTrigger(field.name, row[field.name], 'change', false) });" />
											<div style="position: absolute; right: 4px; top: 0; bottom: 0; margin: auto; height: 16px;">
												<font-awesome-icon style="display: inline-block; margin-right: 4px;" :icon="'spinner'" spin v-if="selectedInlineSearchRow == row && selectedInlineSearchFieldName == field.name && activeInlineSearch[field.name]"></font-awesome-icon>
												<font-awesome-icon style="display: inline-block;" :icon="'search'" v-if="fieldDetail.autoSave === false || fieldDetail.inlineSearch !== undefined"></font-awesome-icon>
											</div>
										</div>
									</template>
								</td>
								<td class="crud-table-shrink" data-title="Options" v-if="!myTable.readOnly">
									<template v-if="buttons">
										<button v-for="btn in inlineButtons" :key="btn.id" class="crud-table-button" :class="[{'crud-table-button-width': (btn.label !== undefined)},btn.customClass ? btn.customClass : '']" @click="doBtn(btn, row)" v-show="btnWhere(btn, row)" type="button"><i aria-hidden="true"><font-awesome-icon :icon="btn.icon" /></i>
											&nbsp;{{ btn.label }} 
											<span v-if="btn.tooltipLabel" class="tooltipcontainer">
												<span class="tooltiptext">{{ blnTranslation && btn.tooltipLabel ? $t(btn.tooltipLabel) : btn.label }}</span>
											</span>
										</button>
									</template>
								</td>
							</tr>
							<tr v-if="selectedInlineSearchRow == row">
								<td colspan="100"> <!-- max out cols -->
									<!-----------------[inline search table]----------------->
									<div v-if="selectedInlineSearchFieldName && isInlineSearch(selectedInlineSearchFieldName)" :id="'inline_' + selectedInlineSearchFieldName" class="inline-search-table">
										<div class="card">
											<table class="table table-hover table-striped table-sm">
												<thead>
													<tr>
														<th scope="col">#</th>
														<th scope="col" v-for="(column, columnIndex) in myTable['fieldDetails'][selectedInlineSearchFieldName]['inlineSearch']['searchColumns']" :key="columnIndex">{{ column }}</th>
													</tr>
												</thead>
												<tbody>
													<tr @click="selectInlineSearchItem(selectedInlineSearchFieldName, item)" v-for="(item, itemIndex) in fOptions['inline_' + selectedInlineSearchFieldName]" :key="item.id">
														<td scope="row">{{ itemIndex + 1 }}</td>
														<td v-for="(column, columnIndex) in myTable['fieldDetails'][selectedInlineSearchFieldName]['inlineSearch']['searchColumns']" :key="columnIndex">{{ item[column] !== undefined ? item[column] : '' }}</td>
													</tr>
													<tr v-if="fOptions['inline_' + selectedInlineSearchFieldName].length == 0">
														<td scope="row">-</td>
														<td colspan="100">No results</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>
						<tr v-if="myTable['showStatistics'] && filteredRows && filteredRows[0]" class="crud-table-statistics">
							<td data-title="" class="crud-table-shrink" v-if="(myTable['showRowCount'] || myTable['selectable'])"><b>{{ blnTranslation ? $t('crud-table.statistics') : myTable.labels.statistics }}</b></td>
							<td class="crud-table-empty" v-if="(myTable['showRowCount'] && myTable['selectable']) && !myTable['readOnly']">&nbsp;</td>
							<td :data-title="toLabel(field.name)" v-for="field in getOrderedFields()" :key="field.name">{{ getStatistics(field.name, filteredRows) }}</td>
							<td class="crud-table-empty" v-if="buttons && !myTable['readOnly']">&nbsp;</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div v-show="myTable['data'] && myTable['pageLimit'] && (myTable['pageLimit'] < myTable['data']['rows'].length) && !myTable['tableFold']" class="crud-table-pagination">
				<ul class="pagination justify-content-center crud-table-pagination">
					<li v-for="value in amountOfPages" :key="'page_' + value" class="page-item" :class="{'active': (page == value - 1)}">
						<a class="page-link" @click="setPage(value)">{{ value }}</a>
					</li>
				</ul>
			</div>
			<div class="card-footer crud-table-panel" v-show="!myTable['tableFold']">
				<div class="crud-table-footer-statistics">
				{{ filteredRows.length }} {{ blnTranslation ? $t('crud-table.of') : myTable.labels.of }} {{ getTotalRows() }} {{ blnTranslation ? $t('crud-table.rows') : myTable.labels.rows }} {{ getSelectedCount() }}
				</div>
				<div class="crud-table-panel-text">
					<b>{{ blnTranslation ? $t('crud-table.lastUpdated') : myTable.labels.lastUpdated }}:</b> {{ $moment(currentTime).format('DD-MM-YYYY HH:mm:ss') }} <span @click="initiate()" class="crud-table-refresh" aria-hidden="true"><font-awesome-icon icon="sync-alt" /></span>
				</div>
			</div>
		</div>
		
		<!-----------------[add and edit part (MODAL)]---------------->
		<div v-if="showModal" class="modal fade crud-table-modal-scroll" style="position:fixed" :class="{'crud-table-modal': showModal, 'show': showModal}" tabindex="-1" role="dialog">
			<form name="sba-modal" v-on:submit.prevent="!checkDisabledModal(myTable['modal']['btn'], 'confirm') && (!myTable.checkModalRequired || myTable['modal']['btn'].id == 'd') && doBtn(myTable['modal']['btn'], myTable['modal']['data'], true)">
				<div class="modal-dialog modal-lg" :class="{'crud-table-modal-dialog': showModal}" role="document">
					<div class="modal-content" :class="{'crud-table-modal-content': showModal}">
						<div class="modal-header" :class="{'crud-table-modal-header': showModal}">
							<h4 class="modal-title" :class="{'crud-table-modal-title': showModal}">
								{{ myTable['modal']['btn']['name'] }}
							</h4>
							<button type="button" class="close" @click="closeModal()" v-if="!modalMessage">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body"  :class="{'crud-table-modal-body': showModal}">
							<!-- modal message, used for explanation to user -->
							<p v-if="myTable['modal']['btn'].message" v-html="myTable['modal']['btn'].message"></p>
							<!-- modal description, used for requirement feedback to user -->
							<p v-html="myTable['modal']['btn'].description"></p>
							<!-- DELETE MANY -->
							<p v-show="myTable['modal']['btn'].action == 'deleteMany'">{{ blnTranslation ? $t('crud-table.beCarefullDeleteMany') : myTable['labels']['beCarefullDeleteMany'] }}</p>
							<p v-for="selectedItem in myTable.selectedItems" :key="selectedItem.id" v-show="myTable['modal']['btn'].action == 'deleteMany'">{{ selectedItem.id }}</p>
							<!-- PUT CSV -->
							<input v-if="myTable['modal']['btn'].action == 'putCSV'" type="file" name="csv_file" id="csv_file" accept=".csv" />
							<template v-for="(field, index) in myTable['modal']['fields']" :key="index">
								<div class="row" v-if="showEditField(field.name)" v-show="!myTable['modal']['btn'].noFields" :class="field['currency'] ? 'sba-crud-table-currency-' + field['currency'] + '-box' : ''">
									<div v-if="!(field.type=='label')" class="col-md-4 crud-table-modal-th">{{ toLabel(field.name) }}<span v-if="field.required">*</span></div>
									<div v-if="(field.type=='label')" class="col-md-4 crud-table-modal-th"></div>
									<div class="col-md-8 crud-table-modal-td">
										<input v-if="field.type=='datetime'" :maxlength="field.max_length" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" type="datetime-local" v-model="myTable['modal']['data'][field.name]"  @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" />
										<input v-if="field.type=='password'" :maxlength="field.max_length" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="password" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" autocomplete="new-password" />
										<input v-if="field.type=='number'" :maxlength="field.max_length" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="number" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :min="field.minimal_amount" step="any" @input="debounceInput" />
										<input v-if="field.type=='time'" :maxlength="field.max_length" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="time" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" />
										<!-- v-model="myTable['modal']['data'][field.name]" -->
										<!-- OLD date - with ISO string
										<input v-if="field.type=='date'" :maxlength="field.max_length" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field')" class="form-control" :class="getClasses(field.name)" :value="myTable['modal']['data'][field.name] && myTable['modal']['data'][field.name].toISOString().split('T')[0]" @input="myTable['modal']['data'][field.name] = $event.target.valueAsDate" type="date" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" />
										-->
										<input v-if="field.type=='date'" :maxlength="field.max_length" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="date" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" />
										<select v-if="field.type=='options'" class="form-control sba-options-full" :class="getClasses(field.name)" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" v-model="myTable['modal']['data'][field.name]" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)">
											<option v-for="option in field.options" v-bind:key="option.id" v-bind:value="option.id">
												{{ option.label }}
											</option>
										</select>
										<!-- combobox //-->
										<input v-if="field.type=='combobox'" 
											@blur="checkOptions(field.name, fOptions[field.name], 'blur')" 
											@click="checkOptions(field.name, fOptions[field.name], 'click')" 
											@change="checkOptions(field.name, fOptions[field.name], 'change')" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="combobox[field.name]" type="text" :list="field.name" />
										<input v-if="field.type=='combobox'" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="hidden" />
										<span v-if="field.type=='combobox'" class="crud-table-input-icon" :class="{'crud-table-input-icon-green': myTable['modal']['data'][field.name]}">
											<font-awesome-icon :icon="myTable['modal']['data'][field.name] ? 'check-circle' : 'exclamation-circle'" />
										</span>
										<span v-if="field.type=='combobox'" class="crud-table-input-icon-delete" @click="checkOptions(field.name, field.options, 'delete')" v-show="checkOptions(field.name, field.options, 'checkDelete') && !checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled">
											<font-awesome-icon icon="times-circle" />
										</span>
										<!-- END combobox //-->
										<datalist :id="field.name">
											<option v-for="item in limitBy(fOptions[field.name] = filterBy(filterBy(filterBy(field.options, optionFilter(field)), selectedOptionFilter(field)), combobox[field.name], 'label'), 50)" :value="item.label" :key="item.id" />
										</datalist>
										<!-- multiselect //-->
										<span v-if="field.type == 'multiselect'">
											<span v-for="option in getSelectedOptions(field.name, field.options)" class="sba-multiselect-box" :key="option.id">
												{{ option.label }}
												<span class="remove" title="remove" @click="removeSelectedOption(field.name, field.options, option)" v-if="!checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled">
													✖
												</span>
											</span>
										</span>
										<input v-if="!checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled && field.type=='multiselect'"
											@blur="checkOptions(field.name, fOptions[field.name], 'blur', true)"
											@click="checkOptions(field.name, fOptions[field.name], 'click', true)"
											@change="checkOptions(field.name, fOptions[field.name], 'change', true)"
											:readonly="checkDisabledModal(myTable['modal']['btn'], 'field', true) || field.disabled"
											class="form-control" :class="getClasses(field.name)"
											v-model="combobox[field.name]" :placeholder=" blnTranslation ? $t('crud-table.add-option') : myTable.labels.addOption" type="text" :list="field.name" />
										<input v-if="field.type=='multiselect'" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="hidden" />
										<span v-if="field.type=='multiselect'" class="crud-table-input-icon fa" :class="{'fa-exclamation-circle': !myTable['modal']['data'][field.name], 'fa-check-circle': myTable['modal']['data'][field.name], 'crud-table-input-icon-green': myTable['modal']['data'][field.name]}"></span>
										<span v-if="field.type=='multiselect'" @click="checkOptions(field.name, field.options, 'delete', true)" v-show="checkOptions(field.name, field.options, 'checkDelete', true) && !checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled" class="crud-table-input-icon-delete">
											<font-awesome-icon icon="times-circle" />
										</span>
										<!-- END multiselect //-->
										<input v-if="field.type=='text' || !field.type" :maxlength="field.max_length" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="text" :required="field.required" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" />
										<template v-if="field.type=='textarea'">
											<textarea v-if="!field.wysiwyg" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" :rows="myTable['fieldDetails'][field.name] && myTable['fieldDetails'][field.name]['rows'] ? myTable['fieldDetails'][field.name]['rows'] : 4" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput"></textarea>
											<!-- Tiny MCE editor -->
											<editor 
												v-if="field.wysiwyg && field.wysiwyg == 'tinymce' && !checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled" 
												api-key="4zxw4rhyxmkuryqjg8dv24p5febzzwja2qawpt3ij1hiz1vy" 
												:init="{
													height: 250,
													menubar: true, 
													init_instance_callback: function(editor) {
														editor.on('blur', function(e) {
															checkRequired();
															changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)
														});
													}}" 
												v-model="myTable['modal']['data'][field.name]"
											/>
											<div class="readonlyWysiwyg" v-if="field.wysiwyg && (checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled)" v-html="myTable['modal']['data'][field.name]"></div>
										</template>
										<input v-if="field.type=='boolean'" type="checkbox" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control crud-table-move-left crud-table-checkbox" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)"/>
										<input v-if="field.type=='standard_file'" type="file" :name="myTable['modal']['data'][field.name]" :accept="myTable['modal']['data'][field.accept]" />
										<!--- Options --->
										<select v-if="field.type=='enum'" class="form-control sba-options-full" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)">
											<option v-for="option in myTable['fieldDetails'][field.name].options" v-bind:key="option.id" v-bind:value="option.id">
												{{ option.name }}
											</option>
										</select>
										<template v-if="field.type=='file'">
											<!-- <vue-dropzone :ref="field.name + '-input'" :id="'input_' + field.name" :options="{...dropzoneOptions, 'maxFiles': (field.multiple == '1' ? null : 1)}" @vdropzone-success="onFileChange(...arguments, field)" @vdropzone-removed-file="onFileDeleted(...arguments, field)" :destroyDropzone="false"></vue-dropzone> -->
											<!-- <div :ref="field.name + '-input'" :id="'input_' + field.name" class="dropzone"></div> -->
											<sba-dropzone :ref="field.name + '-input'" :id="'input_' + field.name" v-model="myTable['modal']['data'][field.name]" :options="field.dropzoneOptions"></sba-dropzone>
										</template>
										<b v-if="field.type=='label'" class="sba-crud-table-field-label">{{ toLabel(field.name) }}</b>
									</div>
								</div>
							</template>
						</div>
						<div class="modal-footer justify-content-center crud-table-modal-message" :class="{'crud-table-modal-footer': showModal, 'crud-table-bg-green':modalMessageSuccess, 'crud-table-bg-red':!modalMessageSuccess, }" v-if="modalMessage">
							<b>{{ blnTranslation ? $t('crud-table.message') : myTable.labels.message }}:</b>&nbsp;{{ modalMessage }}
						</div>
						<div class="modal-footer" :class="{'crud-table-modal-footer': showModal}" v-if="!modalMessage">
							<button type="button" class="btn btn-default" @click="closeModal()" v-if="!myTable['modal']['btn'].cancelHide">{{ blnTranslation && myTable['modal']['btn'].cancelLabel ? $t(myTable['modal']['btn'].cancelLabel) : myTable['modal']['btn'].cancelName }}</button>
							<button type="submit" class="btn btn-primary" v-show="!checkDisabledModal(myTable['modal']['btn'], 'confirm')" :class="myTable['modal']['btn'].confirmClass" :disabled="myTable.checkModalRequired && myTable['modal']['btn'].id != 'd'" v-if="!myTable['modal']['btn'].confirmHide">{{ blnTranslation ? $t(myTable['modal']['btn'].confirmLabel) : myTable['modal']['btn'].confirmName }}</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<!-----------------[add and edit part (WIZARD)]---------------->
		<div v-if="showWizard" class="wizard crud-table-wizard-scroll mb-4" :class="{'crud-table-wizard': showWizard}">
			<form name="sba-wizard" v-on:submit.prevent="!checkDisabledModal(myTable['modal']['btn'], 'confirm') && (!myTable.checkModalRequired || myTable['modal']['btn'].id == 'd') && doBtn(myTable['modal']['btn'], myTable['modal']['data'], true)">
				
				<div class="alert alert-warning sba-crud-table-wizard-alert" :class="[myTable.modal.btn.customAlertClass ? myTable.modal.btn.customAlertClass : '']" role="alert" v-if="myTable.modal.btn.description">
					{{ myTable.modal.btn.description }}
				</div>
				
				<template v-if="myTable.wizardLayout">
					<template v-for="(wizardLayout, layoutType, layoutIndex) in myTable.wizardLayout" :key="'layout_' + layoutIndex">
						<sba-crud-table-fields v-if="layoutType == 'fields'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-fields>
						<sba-crud-table-cards v-if="layoutType == 'cards'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-cards>
						<sba-crud-table-tabs v-if="layoutType == 'tabs'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-tabs>
						<sba-crud-table-rows v-if="layoutType == 'rows'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-rows>
					</template>
				</template>
				
				<template v-if="!myTable.wizardLayout">
					<sba-crud-table-fields :layout="myTable['fields']" :myTable="myTable"></sba-crud-table-fields>
				</template>
				
				<div class="wizard-footer d-flex justify-content-end">
					<button type="button" class="btn btn-default" @click="closeModal()" v-if="!myTable['modal']['btn'].cancelHide">{{ blnTranslation && myTable['modal']['btn'].cancelLabel ? $t(myTable['modal']['btn'].cancelLabel) : myTable['modal']['btn'].cancelName }}</button>
					<button type="submit" class="btn btn-primary" v-show="!checkDisabledModal(myTable['modal']['btn'], 'confirm')" :class="myTable['modal']['btn'].confirmClass" :disabled="myTable.checkModalRequired && myTable['modal']['btn'].id != 'd'" v-if="!myTable['modal']['btn'].confirmHide">{{ blnTranslation ? $t(myTable['modal']['btn'].confirmLabel) : myTable['modal']['btn'].confirmName }}</button>
				</div>
				
				<div class="modal-footer justify-content-center crud-table-modal-message" :class="{'crud-table-modal-footer': showModal, 'crud-table-bg-green':modalMessageSuccess, 'crud-table-bg-red':!modalMessageSuccess, }" v-if="modalMessage">
					<b>{{ blnTranslation ? $t('crud-table.message') : myTable.labels.message }}:</b>&nbsp;{{ modalMessage }}
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { reactive, isReactive } from 'vue';
import moment from 'moment';
import Vue2Filters from 'vue2-filters';
import Editor from '@tinymce/tinymce-vue';
import SbaCrudTableFields from './SbaCrudTable-components/Fields.vue';
import SbaCrudTableCards from './SbaCrudTable-components/Cards.vue';
import SbaCrudTableTabs from './SbaCrudTable-components/Tabs.vue';
import SbaCrudTableRows from './SbaCrudTable-components/Rows.vue';
import SBADropzone from './SbaCrudTable-components/SBADropzone.vue';
import { mapGetters } from 'vuex'

export default {
	name: 'SbaCrudTable',
	props: ['myTable'],
	data() {
		return {
			currentTime: Date.now(),
			page: 0,
			cntSubQueriesTodo: 0,
			unknownFields:[],
			//-----[rows]-----//
			rows: [],
			
			sortRows: {},
			loading: true,
			panelClass: 'panel-default',
			showFilters: true,
			
			//-----[selection boxes]-----//
			combobox: {},
			multiselect: {},
			
			//-----[Upload file]-----//
			dropzoneOptions: {
				dictDefaultMessage: this.$parent.$t('dropzone.drop.label'),
				url: process.env.VUE_APP_API_URL,
				thumbnailHeight: 160,
				maxFilesize: 20,
				destroyDropzone: false,
				previewTemplate: this.dropzoneTemplate(),
				paramName: 'document',
				withCredentials: true,
				headers: {
					'Cache-Control': null, 
					'X-Requested-With': null, 
				}
			},
			
			//-----[modal options]-----//
			showModal: false,
			modalMessage: false,
			modalMessageSuccess: undefined,
			
			//-----[wizard options]-----//
			showWizard: false,
			
			strDefaultOptionField: "Select an option ...",
			filteredOptions: {},
			
			showSearch: false,
			globalSearch: false,
			showCSV: false,
			message: false,
			blnCurrentUser: true,
			blnTranslation: true,
			row: {},
			rowFilter: {},
			rowFilterMin: {},
			rowFilterMax: {},
			statistics: {},
			fOptions: {},
			activeInlineSearch: {},
			selectedInlineSearchRow: {},
			selectedInlineSearchFieldName: null,
			labels: {
				'ready': 'Ready',
				'processSubFields': 'Process subFields',
				'alreadyLoaded': 'already loaded in data.',
				'errorInFetching': 'Error in fetching',
				'dataAvailable': 'Data already available',
				'loading': 'Loading...',
				'selectLabel': 'Select an option ...',
				'warningRequiredStartLabel': '(warning) Field ',
				'warningRequiredEndLabel': ' is required.',
				'rows': 'rows',
				'message': 'Message',
				'lastUpdated': 'Last updated',
				'beCarefullDeleteMany': 'Be carefull! You are deleting all selected rows:',
				'selected': 'Selected',
				'addOption': "Add option",
				'insertSending': 'Sending insert data',
				'insertOk': 'Insert OK',
				'insertNotOk': 'Insert Not OK',
				'insertError': 'Error in fetching',
				'updateSending': 'Sending update data',
				'updateOk': 'Update OK',
				'updateNotOk': 'Update Not OK',
				'updateError': 'Error in fetching',
				'deleteSending': 'Sending delete data',
				'deleteOk': 'Delete OK',
				'deleteNotOk': 'Delete Not OK',
				'deleteError': 'Error in fetching',
				'deSelectAllRows': '✖ De-Select all rows',
				'selectAllRows': '✔ Select all rows',
				'selectFilteredRows': 'f✔ Select filtered rows',
				'deSelectFilteredRows': 'f✖ De-Select filtered rows',
				'showNotSelectedRows': '✖ Show not selected rows',
				'showSelectedRows': '✔ Show selected rows',
				'filterFalse': '✖ False',
				'filterTrue': '✔ True',
				'statistics': 'Statistics',
				'of': 'of',
			},
			baseURL: '',
			cntSubQueriesDone: 0,
			inlineEditValues: {},
			debounceFunction: this.createDebounce(),
			buttons: {
				'C':{
					'id': 'c',
					// 'name': 'Add Row',
					'name': 'Add ' + (this.myTable.label ? this.myTable.label : (this.myTable.name ? this.myTable.name : 'Row')),
					'nameLabel': 'crud-table.insert.button',
					'confirmName': 'Insert',
					'confirmLabel': 'crud-table.insert.confirm',
					'confirmClass': 'success',
					'cancelName': 'Cancel',
					'cancelLabel': 'crud-table.insert.cancel',
					// 'icon': 'fa-plus-square',
					'icon': 'plus-square',
					'header': true,
					'action': 'create',
					'description': null, // for reactivity in wizard
					'function': 'insert', // backend function to check rights
					'tooltipLabel': '',
				},
				'DA':{
					'id': 'da',
					// 'name': 'Delete Selected Rows',
					'name': 'Delete Selected ' + (this.myTable.label ? this.myTable.label : (this.myTable.name ? this.myTable.name : 'Rows')),
					'confirmName': 'Delete Selected Rows',
					'confirmLabel': 'crud-table.delete-selected-rows.confirm',
					'confirmClass': 'success',
					'cancelName': 'Cancel',
					'cancelLabel': 'crud-table.delete-selected-rows.cancel',
					// 'icon': 'fa-trash',
					'icon': 'trash-alt',
					'header': false,
					'inline': false,
					'noFields': true,
					'action': 'deleteMany',
					'description': null, // for reactivity in wizard
					'function': 'deletemany', // backend function to check rights
					'tooltipLabel': 'crud-table.tooltip-create',
				},
				'PUTCSV':{
					'id': 'PUTCSV',
					'name': 'Upload CSV data',
					'confirmName': 'Upload data',
					'confirmLabel': 'crud-table.upload-csv.confirm',
					'confirmClass': 'success',
					'cancelName': 'Cancel',
					'cancelLabel': 'crud-table.upload-csv.cancel',
					// 'icon': 'fa-table',
					'icon': 'table',
					'header': false,
					'inline': false,
					'noFields': true,
					'action': 'putCSV',
					'description': null, // for reactivity in wizard
					'function': 'putcsv', // backend function to check rights
					'tooltipLabel': 'crud-table.tooltip-putcsv',
				},
				'R':{
					'id': 'r',
					// 'name': 'View Row',
					'name': 'View ' + (this.myTable.label ? this.myTable.label : (this.myTable.name ? this.myTable.name : 'Row')),
					'confirmName': 'View',
					'confirmLabel': 'crud-table.view.confirm',
					'confirmClass': 'info',
					'cancelName': 'Cancel',
					'cancelLabel': 'crud-table.view.cancel',
					'inline': true,
					'header': false,
					// 'icon': 'fa-search',
					'icon': 'search',
					'action': 'read',
					'description': null, // for reactivity in wizard
					'function': 'get', // backend function to check rights
					'tooltipLabel': 'crud-table.tooltip-view',
				},
				'U':{
					'id': 'u',
					// 'name': 'Update Row',
					'name': 'Update ' + (this.myTable.label ? this.myTable.label : (this.myTable.name ? this.myTable.name : 'Row')),
					'confirmName': 'Update',
					'confirmLabel': 'crud-table.update.confirm',
					'confirmClass': 'success',
					'cancelName': 'Cancel',
					'cancelLabel': 'crud-table.update.cancel',
					'inline':true,
					// 'icon':'fa-pencil',
					'icon':'pencil-alt',
					'action': 'update',
					'description': null, // for reactivity in wizard
					'function': 'update', // backend function to check rights
					'tooltipLabel': 'crud-table.tooltip-update',
				},
				'D':{
					'id': 'd',
					// 'name': 'Delete Row',
					'name': 'Delete ' + (this.myTable.label ? this.myTable.label : (this.myTable.name ? this.myTable.name : 'Row')),
					'confirmName': 'Delete',
					'confirmLabel': 'crud-table.delete.confirm',
					'confirmClass': 'warning',
					'cancelName': 'Cancel',
					'cancelLabel': 'crud-table.delete.cancel',
					'inline':true,
					// 'icon':'fa-trash',
					'icon':'trash-alt',
					'action': 'delete',
					'description': null, // for reactivity in wizard
					'function': 'delete', // backend function to check rights
					'tooltipLabel': 'crud-table.tooltip-delete',
				},
			},
		}
	},
	watch: {
		cntSubQueriesDone: function (val) {
			if (this.cntSubQueriesDone > 0) {
				let subQueriesKeys = Object.keys(this.myTable.subQueries);

				if (this.cntSubQueriesDone == subQueriesKeys.length) {
					//process subfields
					this.showMessage(this.blnTranslation ? this.$t('crud-table.process-sub-fields') : this.myTable.labels.processSubFields, 0);
					this.processSubFields();
				}
			}
		},
		numberofpages: {
			deep: true,
			handler: function(newVal) {
				if (this.numberofpages <= this.page) {
					this.page = 0;
				}
			}
		},
		sortedRows: {
			deep: true,
			handler: function(newVal) {
				this.rows = newVal;
			}
		},
		selectedRows: {
			deep: true,
			handler: function(newVal) {
				this.myTable.selectedItems = newVal;
			}
		}
	},
	mixins: [Vue2Filters.mixin],
	components: { Editor, 'sba-dropzone': SBADropzone, 'sba-crud-table-fields': SbaCrudTableFields, 'sba-crud-table-cards': SbaCrudTableCards, 'sba-crud-table-tabs': SbaCrudTableTabs, 'sba-crud-table-rows': SbaCrudTableRows, },
	methods: {
		// check inline search
		checkInlineSearch: function(evt, row, fieldName, reverse = false) {
			// check reverse (on blur)
			if (reverse && this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName].inlineSearch) {
				// reset active inline search row
				this.selectedInlineSearchRow = {};
				// set active inline search field name
				this.selectedInlineSearchFieldName = null;
			} else if (this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName].inlineSearch) {
				// set active inline search row
				this.selectedInlineSearchRow = row;
				// set active inline search field name
				this.selectedInlineSearchFieldName = fieldName;
				
				// check if inline search
				if (this.myTable.fieldDetails[fieldName] !== undefined && this.myTable.fieldDetails[fieldName].inlineSearch) {
					
					// initialize background search object
					let searchObject = {
						'controller': this.myTable.fieldDetails[fieldName]['inlineSearch']['controller'],
						'view': this.myTable.fieldDetails[fieldName]['inlineSearch']['view'],
						'filters': [],
						'limit': 20,
					};
					
					// build initial filter on field name
					searchObject['filters'].push({
						'name': this.myTable.fieldDetails[fieldName]['inlineSearch']['field'],
						'value': row[fieldName],
						'strict': this.myTable.fieldDetails[fieldName] !== undefined && this.myTable.fieldDetails[fieldName].strict,
					});
					
					// validate default filters
					if (this.myTable.fieldDetails[fieldName]['inlineSearch'].defaultFilters !== undefined && Array.isArray(this.myTable.fieldDetails[fieldName]['inlineSearch'].defaultFilters)) {
						this.myTable.fieldDetails[fieldName]['inlineSearch'].defaultFilters.forEach((defaultFilter) => {
							if (typeof defaultFilter === 'object' && defaultFilter.name && defaultFilter.value) {
								searchObject['filters'].push(defaultFilter);
							}
						});
					}
					
					// validate additional fields
					if (this.myTable.fieldDetails[fieldName]['inlineSearch'].additionalFields !== undefined && typeof this.myTable.fieldDetails[fieldName]['inlineSearch'].additionalFields === 'object') {
						// loop through additional filter fields
						for (let additionalRowField in this.myTable.fieldDetails[fieldName]['inlineSearch'].additionalFields) {
							// set additional filter field
							let additionalFilterField = this.myTable.fieldDetails[fieldName]['inlineSearch'].additionalFields[additionalRowField];
							// validate row content for filter
							if (row[additionalRowField] !== undefined && row[additionalRowField] !== null && row[additionalRowField] !== '') {
								// add filter from additional fields
								searchObject['filters'].push({
									'name': additionalFilterField,
									'value': row[additionalRowField],
									'strict': this.myTable.fieldDetails[additionalRowField] !== undefined && this.myTable.fieldDetails[additionalRowField].strict,
								});
							}
						}
					}
					
					// set active search
					this.activeInlineSearch[fieldName] = true;
					// initialize empty options
					this.fOptions['inline_' + fieldName] = [];
					
					// create data request
					this.processDataRequest(searchObject).then((response) => {
						// process response
						this.fOptions['inline_' + fieldName] = [];
						if (response.status) {
							this.fOptions['inline_' + fieldName] = response.data;
						} else {
							this.fOptions['inline_' + fieldName] = [];
						}
						
						this.activeInlineSearch[fieldName] = false;
					});
				}
			}
		},
		// select inline search item
		selectInlineSearchItem: function(fieldName, item) {
			// validate inline search
			if (this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName].inlineSearch) {
				// validate update fields
				if (this.myTable['fieldDetails'][fieldName]['inlineSearch'].updateFields !== undefined && typeof this.myTable['fieldDetails'][fieldName]['inlineSearch'].updateFields === 'object') {
					// loop through update fields
					for (let rowField in this.myTable['fieldDetails'][fieldName]['inlineSearch'].updateFields) {
						// fetch item field from row field
						let itemField = this.myTable['fieldDetails'][fieldName]['inlineSearch'].updateFields[rowField];
						
						// update row field
						this.selectedInlineSearchRow[rowField] = item[itemField];
					}
				}
			}
			
			// check inline required after select
			this.checkInlineRequired(this.selectedInlineSearchRow, fieldName);
			
			// reset active inline search row
			this.selectedInlineSearchRow = {};
			// set active inline search field name
			this.selectedInlineSearchFieldName = null;
		},
		// debounce function
		createDebounce: function() {
			let timeout = null;
			return function (fnc, delayMs) {
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					fnc();
				}, delayMs || 500);
			};
		},
		// debounce input
		debounceInput: function() {
			return this.debounce(function (e) {
				this.$store.dispatch('updateInput', e.target.value);
			}, 500);
		},
		// debounce
		debounce: function(func, wait, immediate) {
			var timeout;
			return function() {
				var context = this, args = arguments;
				var later = function() {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		},
		//CSV
		getCSV: function(){
			if(this.myTable.controller){
				if ( window.confirm('Do you want a CSV file?') ) {
					if ( window.confirm('Include the table data?') ) {
						let tmpCSVurl = 'getCSV/true';
						if(this.myTable.getCSVurl){
							tmpCSVurl = this.myTable.getCSVurl;
						}
						window.open(this.baseURL + '/' + this.myTable.controller + '/' + tmpCSVurl, '_blank');
					}else{
						window.open(this.baseURL + '/' + this.myTable.controller + '/getCSV', '_blank');
					}
				}
			}
		},
		inlineEdit: function($event, row, fieldName) {
			//check if inline edit enabled
			if (this.myTable['inlineEditEnabled']) {
				//old inline document restore
				if(!this.inlineEditValues){
					this.inlineEditValues = {
						enabled: true,
					};
				}
				if($event.currentTarget.id && !this.inlineEditValues){
					let element = document.getElementById($event.currentTarget.id);
					this.inlineEditValues.value = element.innerHTML;

					let btn = this.buttons['U'];
					this.processBtns(btn, row, false);
				}
			}
		},
		enableGetCSV: function(blnSet){
			this.showCSV = blnSet;
		},
		enablePutCSV: function(blnSet){
			this.buttons['PUTCSV']['header'] = blnSet;
		},
		getSelectedCount: function() {
			if (this.myTable.selectable) {
				
				if (this.myTable.selectedItems.length !== undefined) {
					return ' | ' + (this.blnTranslation ? this.$t('crud-table.labels.selected') : this.myTable.labels.selected) + ': ' + this.myTable.selectedItems.length;
				}
				
			}
			
			return '';
		},
		getClasses: function(fieldName){
			let strClasses = "";
			if(this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName].class){
				let objClass = this.myTable['fieldDetails'][fieldName].class;
				if (typeof objClass == 'string') {
					strClasses = strClasses + ' ' + objClass;
				} else if (Array.isArray(objClass)) {
					objClass.forEach((strClass) => {
						strClasses = strClasses + ' ' + strClass;
					});
				}
			}
			return strClasses;
		},
		getInlineClasses: function(fieldName){
			let strClasses = "";
			if(this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName].inlineClass){
				let objClass = this.myTable['fieldDetails'][fieldName].inlineClass;
				if (typeof objClass == 'string') {
					strClasses = strClasses + ' ' + objClass;
				} else if (Array.isArray(objClass)) {
					objClass.forEach((strClass) => {
						strClasses = strClasses + ' ' + strClass;
					});
				}
			}
			return strClasses;
		},
		getSelectedOptions: function(fieldName, fieldOptions) {
			// initiate when first called on new model
			if (this.multiselect[fieldName] == undefined && Array.isArray(this.myTable['modal']['data'][fieldName])) {
				// rewrite from this.$set
				this.multiselect[fieldName] = this.myTable['modal']['data'][fieldName].slice();
			}
			
			if (!Array.isArray(this.multiselect[fieldName])) {
				return [];
			}

			let result = fieldOptions.filter((option) => {
				return this.multiselect[fieldName].indexOf(option.id) !== -1;
			});

			if (result && result.length) {
				return result;
			} else {
				return [];
			}
		},
		removeSelectedOption: function(fieldName, fieldOptions, option) {
			if (!Array.isArray(this.multiselect[fieldName]) || !option || !option.id) {
				return false;
			}

			let index = this.multiselect[fieldName].indexOf(option.id);
			if (index !== -1) {
				this.multiselect[fieldName].splice(index, 1);
			}
			
			this.myTable['modal']['data'][fieldName] = this.multiselect[fieldName];

			return true;
		},
		
		selectedOptionFilter: function(field) {
			let value = this.myTable['modal']['data'][field.name];

			if (!Array.isArray(value)) {
				return function(option) {
					return true;
				}
			}

			return function(option) {
				return option && option.id && value.indexOf(option.id) === -1;
			}
		},
		
		//----------[trLayout]----------//
		trLayout: function(row) {
			let strReturn = '';
			if(this.myTable['style'] && this.myTable['style']['row']){
				for (let strClass in this.myTable['style']['row']) {
					let checkFields = this.myTable['style']['row'][strClass];
					for (let checkField in checkFields) {
						let fieldCompare = checkFields[checkField];
						if(row[checkField] !== undefined){
							if(this.getCompare(row[checkField], fieldCompare)){
								strReturn = strClass;
							}
						}
					}
				}
			}
			
			//return class
			return strReturn;
		},
		
		//----------[tdLayout]----------//
		tdLayout: function(fieldName, fieldValue) {
			let strReturn = '';
			if(this.myTable['style'] && this.myTable['style']['cell']){
				for (let strClass in this.myTable['style']['cell']) {
					let checkFields = this.myTable['style']['cell'][strClass];
					for (let checkField in checkFields) {
						let fieldCompare = checkFields[checkField];
						if (fieldName == checkField || checkField.substring(0, 7) == '_global') {
							if (this.getCompare(fieldValue, fieldCompare)) {
								strReturn = strClass;
							}
						}
					}
				}
			}
			
			//return class
			return strReturn;
		},
		
		//----------[spanLayout]----------//
		spanLayout: function(fieldName, fieldValue) {
			let strReturn = '';
			if(this.myTable['style'] && this.myTable['style']['content']){
				for (let strClass in this.myTable['style']['content']) {
					let checkFields = this.myTable['style']['content'][strClass];
					for (let checkField in checkFields) {
						let fieldCompare = checkFields[checkField];
						if (fieldName == checkField || checkField.substring(0, 7) == '_global') {
							if (this.getCompare(fieldValue, fieldCompare)) {
								strReturn = strClass;
							}
						}
					}
				}
			}
			
			//return class
			return strReturn;
		},
		
		//----------[message]----------//
		showMessage: function(message, timeout) {
			if (message) {
				this.message = message;
				if(timeout === undefined){
					timeout = 1000;
				}
				if(timeout > 0){
					setTimeout(function(){
						this.message = false;
					}.bind(this), timeout);
				}
			}
		},
		showModalMessage: function(message, timeout, error) {
			if(message){
				this.modalMessage = message;
				if(timeout === undefined){
					timeout = 1000;
					this.modalMessageSuccess = true;
				}else{
					this.modalMessageSuccess = false;
				}
				if(error !== undefined){
					this.modalMessageSuccess = !error;
				}
				if (timeout > 0) {
					setTimeout(function() {
						this.modalMessage = '';
						if (this.myTable.showModal || this.myTable.showWizard) {
							this.closeModal();
						}
					}.bind(this), timeout);
				} else if (timeout == 0) {
					setTimeout(function() {
						this.modalMessage = '';
					}.bind(this), 2000);
				}
				//Give sign to parent
				if(this.$parent['modalUpdate']){
					this.$parent['modalUpdate'](this.myTable.name);
				}
			}
		},
		
		//----------[compare]----------//
		doCompare: function(left, compare, right) {
			switch(compare) {
				case '<>':
					return (left != right);
				case '!=':
					return (left != right);
				case '==':
					return (left == right);
				case '>=':
					return (left >= right);
				case '<=':
					return (left <= right);
				case '>':
					return (left > right);
				case '<':
					return (left < right);
				default:
					return false;
			}
		},
		getCompare: function(left, compareright) {
			if (compareright.substring(0,1) == '>' || compareright.substring(0,1) == '<') {
				return this.doCompare(left, compareright.substring(0,1), compareright.substring(1));
			} else {
				return this.doCompare(left, compareright.substring(0,2), compareright.substring(2));
			}
		},
		
		/*-------[field Options]----------------*/
		changeFieldTrigger: function(fieldName, fieldValue, eventType) {
			// check auto save
			if (this.myTable['modal'] !== undefined && this.myTable['modal']['btn'] !== undefined && this.myTable['modal']['btn'].autoSave) {
				// check requirements
				if (!this.checkDisabledModal(this.myTable['modal']['btn'], 'confirm') && (!this.myTable.checkModalRequired || this.myTable['modal']['btn'].id == 'd')) {
					// perform action
					this.doBtn(this.myTable['modal']['btn'], this.myTable['modal']['data'], true);
				}
			}
			
			// call on change field function
			if (this.myTable.onChangeFieldFunction !== undefined) {
				this.myTable.onChangeFieldFunction(this.myTable.name, fieldName, fieldValue, eventType);
			}
			
			// update to parent scope
			if (this.$parent['sbaCrudTableChange']) {
				this.$parent['sbaCrudTableChange'](this.myTable.name, fieldName, fieldValue, eventType);
			}
		},
		
		/*
			searchObject: {
				'controller': '', // required
				'view': '',
				'function': '',
				'getId': '',
				'filters': [
					{
						'name': 'name',
						'value': '!',
						'strict': true,
					},
					{
						'name': 'name',
						'value': '!!',
						'strict': true,
					},
					{
						'name': 'name',
						'value': 'Jack',
						'strict': true,
					},
				],
				minFilters: [
					{
						'name': 'age',
						'value': 5,
						'equal': true,
					},
				],
				maxFilters: {
					{
						'name': 'age',
						'value': 10,
						'equal': false,
					},
				},
				sort: {
					'name': {
						'name': 'name',
						'ASC': false,
					},
					'age': {
						'name': 'age',
						'ASC': true,
					},
				},
				limit: 100,
				offset: 0,
			}
		*/
		
		processDataRequest: function(searchObject) {
			// set default function
			let varFunction = 'getAll';
			// rewrite function if set
			if (searchObject.function !== undefined) {
				varFunction = searchObject.function;
			}
			
			// set default id
			let getId = '';
			// rewrite id if set
			if (searchObject.getId !== undefined && searchObject.getId) {
				getId = '/' + searchObject.getId;
			}
			
			// build request parameter
			let requestParameter = {};
			
			// set view if set
			if (searchObject.view !== undefined) {
				requestParameter['select'] = searchObject.view;
			}
			
			// set filters if set
			if (searchObject.filters !== undefined && Array.isArray(searchObject.filters) && searchObject.filters.length) {
				// initiate where
				requestParameter['where'] = [];
				
				let whereObject = {"AND": []};
				
				// loop through filters
				searchObject.filters.forEach((filter) => {
					
					// validate filter value
					if (filter.value !== undefined) {
						// check strict or number
						if (filter.strict || typeof filter.value === 'number') {
							// initialize filter term
							let filterTerm = {
								'term': {},
							};
							filterTerm['term'][filter.name] = filter.value;
							whereObject['AND'].push(filterTerm);
						} else if (typeof filter.value === 'string') {
							// initialize filter like
							let filterLike = {
								'like': {},
							};
							filterLike['like'][filter.name] = filter.value;
							whereObject['AND'].push(filterLike);
						}
					}
				}) // end of foreach
				
				requestParameter['where'].push(whereObject);
			}
			
			// set min filters if set
			if (searchObject.minFilters !== undefined && Array.isArray(searchObject.minFilters) && searchObject.minFilters.length) {
				
				// loop through filters
				searchObject.minFilters.forEach((minFilter) => {
					// validate filter value
					if (minFilter.value !== undefined && !isNaN(minFilter.value) && minFilter.value !== "") {
						// initiate where
						if (requestParameter['where'] === undefined) {
							requestParameter['where'] = [];
						}
					
						// initialize filter range
						let filterRange = {
							'range': {},
						};
						filterRange['range'][minFilter.name] = {};
						if (minFilter.equal !== undefined && minFilter.equal) {
							filterRange['range'][minFilter.name]['gte'] = minFilter.value;
						} else {
							filterRange['range'][minFilter.name]['gt'] = minFilter.value;
						}
						
						// push to where
						requestParameter['where'].push(filterRange);
					}
				});
			}
			
			// set max filters if set
			if (searchObject.maxFilters !== undefined && Array.isArray(searchObject.maxFilters) && searchObject.maxFilters.length) {
				
				// loop through filters
				searchObject.maxFilters.forEach((maxFilter) => {
					// validate filter value
					if (maxFilter.value !== undefined && !isNaN(maxFilter.value) && maxFilter.value !== "") {
						// initiate where
						if (requestParameter['where'] === undefined) {
							requestParameter['where'] = [];
						}
					
						// initialize filter range
						let filterRange = {
							'range': {},
						};
						filterRange['range'][maxFilter.name] = {};
						if (maxFilter.equal !== undefined && maxFilter.equal) {
							filterRange['range'][maxFilter.name]['lte'] = maxFilter.value;
						} else {
							filterRange['range'][maxFilter.name]['lt'] = maxFilter.value;
						}
						
						// push to where
						requestParameter['where'].push(filterRange);
					}
				});
			}
			
			// set sort if set
			if (searchObject.sort !== undefined && typeof searchObject.sort === 'object') {
				
				// loop through sorts
				for (let field in searchObject.sort) {
					// validate sort value
					if (searchObject.sort[field] !== undefined && typeof searchObject.sort[field] === 'object') {
						let sortObj = searchObject.sort[field];
						
						// initiate sort
						if (requestParameter['sort'] === undefined) {
							requestParameter['sort'] = [];
						}
						
						// initiate sort request object
						let sortRequestObj = {};
						sortRequestObj[field] = sortObj['asc'] !== undefined && sortObj['asc'] ? 'asc' : 'desc';
						
						// push sort request object to array
						requestParameter['sort'].push(sortRequestObj);
					}
				}
			}
			
			// set limit if set
			if (searchObject.limit !== undefined && !isNaN(searchObject.limit)) {
				requestParameter['limit'] = searchObject.limit;
			}
			
			// set offset if set
			if (searchObject.offset !== undefined && !isNaN(searchObject.offset)) {
				requestParameter['offset'] = searchObject.offset;
			}
			
			// initiate string request parameter
			let strRequestParameter = '';
			if (typeof requestParameter === 'object' && Object.keys(requestParameter).length > 0) {
				strRequestParameter = '?q=' + JSON.stringify(requestParameter);
			}
			
			// return perform request
			return this.req('GET', this.baseURL + '/' + searchObject.controller + '/' + varFunction + getId + strRequestParameter);
		},
		
		checkOptions: function(fieldName, fieldOptions, eventType, multiselect) {
			if (!multiselect) {
				multiselect = false;
			}

			let init = false;
			if (this.combobox[fieldName] === undefined) {
				init = true;
			}
			
			//filter
			let objFilter = [];
			
			//field
			let field = {};
			field.options = fieldOptions;
			field.name = fieldName;
			field.value = this.myTable['modal']['data'][field.name];
			field.label = this.getOptionLabel(field);
			
			switch (eventType) {
				case 'delete':
					if (multiselect) {
						this.multiselect[field.name] = [];
						this.myTable['modal']['data'][field.name] = [];
					} else {
						this.myTable['modal']['data'][field.name] = null;
					}
					field.value = null;
					this.combobox[field.name] = "";
					this.resetOption(field);
					
					//update to parent scope
					this.changeFieldTrigger(fieldName, objFilter[0], eventType);
					break;
				case 'checkDelete':
					//initiate
					if (init) {
						this.checkOptions(fieldName, fieldOptions, 'init');
					}
					if (this.combobox[field.name] !== undefined && this.combobox[field.name].length > 0 && field.label) {
						return true;
					} else {
						return false;
					}
				case 'click':
					if (!field.label) {
						this.combobox[field.name] = "";
					}
					break;
				case 'change':
					objFilter = this.filterBy(field.options, this.combobox[field.name], 'label');
					if (objFilter.length == 1) {
						if (multiselect) {
							// must equal exactly to add
							if (this.multiselect[field.name] === undefined) {
								// rewrite from this.$set
								this.multiselect[field.name] = [];
							}
							if (objFilter[0]['label'] == this.combobox[field.name]) {
								this.multiselect[field.name].push(objFilter[0].id);
								this.myTable['modal']['data'][field.name] = this.multiselect[field.name];
							}
						} else {
							this.myTable['modal']['data'][field.name] = objFilter[0].id;
						}
					
						//update to parent scope
						this.changeFieldTrigger(fieldName, objFilter[0], eventType);
					} else {
						if (!multiselect) {
							this.myTable['modal']['data'][field.name] = null;
						}
					}
					this.checkRequired();
					break;
				case 'init':
					if (field.label) {
						this.combobox[field.name] = field.label;
					} else {
						this.resetOption(field);
					}
					this.checkRequired();
					break;
				case 'blur':
					objFilter = this.filterBy(field.options, this.combobox[fieldName], 'label');
					if (objFilter.length == 1) {
						if (!multiselect) {
							this.myTable['modal']['data'][field.name] = objFilter[0].id;
						}
						
						this.combobox[fieldName] = objFilter[0].label;
					} else {
						if (!multiselect) {
							this.myTable['modal']['data'][field.name] = null;
						}
						field.value = null;
					}
					this.checkRequired();
					break;
			}
		},
		
		getOptionLabel: function(field) {
			if(field.value == null || field.value == 0){
				return false;
			}

			if (Array.isArray(field.value)) {
				return false;
			}

			let result = field.options.filter((option) => { return option['id'] === field.value; });
			if(result && result.length == 1 && result[0] && result[0].label){
				return result[0].label;
			}else{
				return false;
			}
		},
		
		resetOption: function(field){
			//default
			if(field.value == null && field.options[0]){
				this.combobox[field.name] = field.options[0].label;
			}else if(!field.value){
				this.combobox[field.name] = this.blnTranslation ? this.$t('crud-table.select-label') : this.myTable.labels.selectLabel;
			}
		},
		
		checkDisabledModal: function(btn, part) {
			if(btn && btn.id){
				switch(part){
					case 'confirm':
						return (btn.id.toLowerCase() == 'r');
					case 'field':
						return (btn.id.toLowerCase() == 'r' || btn.id.toLowerCase() == 'd');
				}
			}
		},
		
		onChangeFilter: function() {
			//update rowFilter to parent scope
			if (this.$parent['sbaCrudTableFilterChange']) {
				this.$parent['sbaCrudTableFilterChange'](this.myTable.name, this.rowFilter, this.rowFilterMin, this.rowFilterMax);
			}
			
			// search in backend
			if (this.myTable.backend !== undefined && this.myTable.backend) {
				// after settings are complete, fetch the data
				this.getData();
			}
		},
		
		optionFilter: function(field) {
			if (!this.myTable.fieldDetails || (this.myTable.fieldDetails && !this.myTable.fieldDetails[field.name]) || (this.myTable.fieldDetails && this.myTable.fieldDetails[field.name] &&!this.myTable.fieldDetails[field.name].optionFilter)) {
				return function(item) {
					return true;
				};
			}
			return (item) => {
				let filters = this.myTable.fieldDetails[field.name].optionFilter;
				if (!Array.isArray(filters)) {
					filters = [filters];
				}

				let show = true;
				filters.forEach((filter) => {
					if (filter.ffield === undefined || filter.lfield === undefined || filter.controller === undefined || this.myTable.data[filter.controller] === undefined) {
						return;
					}
					
					if (filter.link === undefined) {
						//lfield - > subtable field
						//ffield - > current row
						let fltOptions = this.myTable.data[filter.controller].filter((row) => {
							return row['id'] == item['id'] && row[filter.lfield] == this.myTable.modal.data[filter.ffield];
						});
						show = show && (fltOptions && fltOptions[0]);
					} else {
						if (this.myTable.data[filter.link] === undefined || this.myTable.subQueries[filter.link] === undefined || this.myTable.subQueries[filter.link].lfield === undefined || this.myTable.subQueries[filter.link].ffield === undefined) {
							return;
						}
						
						//lfield - > subtable field
						//ffield - > current row
						//link - > link table
						let linkOptions = this.myTable.data[filter.link].filter((row) => {
							return this.myTable.modal.data[this.myTable.subQueries[filter.link].lfield] == row[this.myTable.subQueries[filter.link].ffield];
						}).map((row) => {
							return row[filter.ffield];
						});
						
						let fltOptions = this.myTable.data[filter.controller].filter((row) => {
							return row['id'] == item['id'] && linkOptions.indexOf(row[filter.lfield]) > -1;
						});
						show = show && (fltOptions && fltOptions[0]);
					}

				});
				return show;
			}
		},
		
		//----------buttons----------//
		checkInlineRequired: function(row, changedFieldName) {
			let blnSubmit = true;
			for (let fieldName in this.myTable.fieldDetails) {
				let field = this.myTable.fieldDetails[fieldName];
				
				if (!blnSubmit) { return false; }
				
				if (field.required && (row[fieldName] === undefined
					|| (row[fieldName] !== undefined
					&& (row[fieldName] === null
					|| row[fieldName] === ""
					|| (field.type == 'options' && row[fieldName] == 0)
					|| (field.type == 'combobox' && row[fieldName] == 0)
					|| (field.type == 'number' && isNaN(row[fieldName])))))) {
					blnSubmit = false;
				}
			}
			
			// check if submit
			if (blnSubmit) {
				// handle autosave
				if (this.myTable.autoSave && (this.myTable.fieldDetails[changedFieldName] === undefined || this.myTable.fieldDetails[changedFieldName].autoSave === undefined || this.myTable.fieldDetails[changedFieldName].autoSave)) {
					// auto save row
					this.processBtns({
						action: 'update',
					}, row, true);
				}
			}
		},
		
		//----------buttons----------//
		checkRequired: function() {
			if(!this.myTable.modal.btn){
				return;
			}
			let blnSubmit = false;
			this.myTable.modal.fields.forEach((field) => {
				if (blnSubmit) {
					return;
				}

				if(!this.myTable.fieldDetails){
					this.myTable.fieldDetails = {};
				}
				if(!this.myTable.modal.data){
					this.myTable.modal.data = {};
				}
				if (field.required && (this.myTable.modal.data[field.name] === undefined
					|| (this.myTable.modal.data[field.name] !== undefined
					&& (this.myTable.modal.data[field.name] === null
					|| this.myTable.modal.data[field.name] === ""
					|| (field.type == 'options' && this.myTable.modal.data[field.name] == 0)
					|| (field.type == 'combobox' && this.myTable.modal.data[field.name] == 0)
					|| (field.type == 'number' && isNaN(this.myTable.modal.data[field.name])))))) {
					this.myTable.modal.btn.description = this.blnTranslation ? this.$t('crud-table.warning-required.label', { label:  field.label }) : this.myTable.labels.warningRequiredStartLabel + field.label + this.myTable.labels.warningRequiredEndLabel;
					blnSubmit = true;
				}
			});
			if(!blnSubmit){
				this.myTable.modal.btn.description = '';
			}
			
			this.myTable.checkModalRequired = blnSubmit;
		},
		
		closeModal: function() {
			this.showModal = false;
			this.myTable.showModal = false;
			
			this.showWizard = false;
			this.myTable.showWizard = false;
			
			if(this.$parent['modalClose']){
				this.$parent['modalClose'](this.myTable.name);
			}
		},
		
		doBtn: function(btn, row, blnSubmit) {
			if(!blnSubmit){
				blnSubmit = false;
			}
			if(!row){
				row = {};
			}
			if(btn && btn.action){
				if(btn.parentScope){
					this.$parent[btn.action](btn, row, blnSubmit);
				}else if(this[btn.action]){
					this[btn.action](btn, row, blnSubmit);
				}
			}
		},
		
		btnWhere: function(btn, row) {
			if (btn.where && btn.whereField) {
				let tmpWhere = btn.where;
				let tmpWhereField = btn.whereField;
				
				//array of 2 parts
				if (typeof tmpWhere == "object") {
					let blnResult = false;
					for (let compare in tmpWhere) {
						let right = tmpWhere[compare];
						let left = '';
						if (!btn.whereOnChangeTo && row['original_' + tmpWhereField]) {
							left = row['original_' + tmpWhereField];
						} else {
							left = row[tmpWhereField];
						}
						if (this.doCompare(left, compare, right)) {
							blnResult = true;
						}
					}
					return blnResult;
				}
			} else {
				return true;
			}
		},
		
		putCSV: function(btn, row, blnSubmit) {
			this.processBtns(btn,row,blnSubmit);
		},
		
		update: function(btn, row, blnSubmit) {
			this.processBtns(btn,row,blnSubmit);
		},
		
		create: function(btn, row, blnSubmit) {
			this.processBtns(btn,row,blnSubmit);
		},
		
		read: function(btn, row, blnSubmit) {
			this.processBtns(btn,row,blnSubmit);
		},
		
		delete: function(btn, row, blnSubmit) {
			this.processBtns(btn,row,blnSubmit);
		},
		
		deleteMany: function(btn, row, blnSubmit) {
			this.processBtns(btn,row,blnSubmit);
		},
		
		processBtns: function(btn, row, blnSubmit) {
			// check for inline row insert
			if (this.myTable.inline !== undefined && this.myTable.inline) {
				
				// loop through fields
				this.myTable.data.fields.forEach((field) => {
					// validate field detail
					if (this.myTable.fieldDetails && this.myTable.fieldDetails[field.name]) {
						// set field detail
						let fieldDetail = this.myTable.fieldDetails[field.name];
						
						// check for default
						if (fieldDetail.default !== undefined && (row[field.name] === undefined || row[field.name] === null)) {
							row[field.name] = fieldDetail.default;
						}
					}
				});
				
				if (btn.action == 'create') {
					//insert
					this.myTable['data']['rows'].push(row);
					this.rows = this.myTable['data']['rows'];
					
					// directly insert row
					blnSubmit = true;
				}
			}
		
			if (!blnSubmit) {
				
				// rewrite from this.$set
				this.myTable['modal'] = {};

				this.myTable.modal.fields = [];
				// rewrite from this.$set
				this.myTable.modal.data = {};
				
				// initiate combobox / multiselect variables
				this.combobox = reactive({});
				this.multiselect = reactive({});
				
				if(!btn.noFields){
					this.myTable.modal.fields = this.myTable.data.fields;
					this.myTable.modal.data = this.restoreOriginals(row);
				}
				
				let options = this.getAllOptions();
				this.myTable.modal.fields.forEach((field) => {
					if (options[field.name]) {
						if(this.myTable.fieldDetails && this.myTable.fieldDetails[field.name] && this.myTable.fieldDetails[field.name].type && this.myTable.fieldDetails[field.name].type != 'number' && this.myTable.fieldDetails[field.name].type != 'combobox'){
							field.type = this.myTable.fieldDetails[field.name].type;
						}else{
							field.type = 'combobox';
						}
						field.options = options[field.name];
						if (!this.myTable.modal.data[field.name]) {
							// multiselect
							if (this.myTable.fieldDetails && this.myTable.fieldDetails[field.name] && this.myTable.fieldDetails[field.name].link) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = [];
								// multiselect default must be array
								if (this.myTable.fieldDetails[field.name].default && Array.isArray(this.myTable.fieldDetails[field.name].default)) {
									// rewrite from this.$set
									this.myTable.modal.data[field.name] = this.myTable.fieldDetails[field.name].default;
									this.multiselect[field.name] = this.myTable.fieldDetails[field.name].default;
								}
							} else if (this.myTable.fieldDetails && this.myTable.fieldDetails[field.name] && this.myTable.fieldDetails[field.name].default) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = this.myTable.fieldDetails[field.name].default;
							} else {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = null;
							}
						}
						
						// initiate combobox after default has been set
						if (field.type === 'combobox') {
							this.checkOptions(field.name, options[field.name], 'init');
						}
						
						if (this.myTable.fieldDetails && this.myTable.fieldDetails[field.name]) {
							let fieldDetails = this.myTable.fieldDetails[field.name];

							//required value
							if(fieldDetails.required !== undefined && !fieldDetails.hideEdit){
								field.required = fieldDetails.required;
							}
							//disabled value
							if(fieldDetails.disabled !== undefined) {
								field.disabled = fieldDetails.disabled;
							}
							//label value
							if(fieldDetails.label !== undefined){
								field.label = fieldDetails.label;
							}else{
								field.label = field.name;
							}
							//inlineSearch value
							if (fieldDetails.inlineSearch !== undefined) {
								field.inlineSearch = fieldDetails.inlineSearch;
							}
						}
					} else if(this.myTable.fieldDetails && this.myTable.fieldDetails[field.name] && this.myTable.fieldDetails[field.name].type) {
						let fieldDetails = this.myTable.fieldDetails[field.name];

						//required value
						if(fieldDetails.required !== undefined && !fieldDetails.hideEdit){
							field.required = fieldDetails.required;
						}
						//disabled value
						if(fieldDetails.disabled !== undefined) {
							field.disabled = fieldDetails.disabled;
						}
						//label value
						if(fieldDetails.label !== undefined){
							field.label = fieldDetails.label;
						}else{
							field.label = field.name;
						}
						//customClass value
						if (fieldDetails.customClass !== undefined && fieldDetails.customClass) {
							field.customClass = fieldDetails.customClass;
						}

						//datetime convertion
						if(fieldDetails.type == 'datetime'){
							field.type = fieldDetails.type;
							//datetime
							if (this.myTable.modal.data[field.name] && this.myTable.modal.data[field.name] == "0000-00-00 00:00:00") {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = null;
							}
							if (fieldDetails.default && !this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = new Date(fieldDetails.default);
							} else if (!this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = null;
							}
							//set convert
							if (this.myTable.modal.data[field.name]) {
								this.myTable.modal.data[field.name] = this.$moment(this.myTable.modal.data[field.name]).format(this.$moment.HTML5_FMT.DATETIME_LOCAL);
							}
						}else if(fieldDetails.type == 'date'){
							//date field
							field.type = fieldDetails.type;
							//date
							if(this.myTable.modal.data[field.name] && this.myTable.modal.data[field.name] =="0000-00-00"){
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = null;
							}
							if(fieldDetails.default && !this.myTable.modal.data[field.name]){
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = new Date(fieldDetails.default);
							}else if(!this.myTable.modal.data[field.name]){
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = null;
							}
							//set convert
							if (this.myTable.modal.data[field.name]) {
								this.myTable.modal.data[field.name] = this.$moment(this.myTable.modal.data[field.name]).format(this.$moment.HTML5_FMT.DATE);
							}
						}else if(fieldDetails.type == 'time'){
							//time field
							field.type = fieldDetails.type;
							//default
							if (this.myTable.modal.data[field.name] && (this.myTable.modal.data[field.name] =="00:00:00" || this.myTable.modal.data[field.name] =="00:00")) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = null;
							}
							if (fieldDetails.default && !this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = fieldDetails.default;
							}else if (!this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = null;
							}
							//set convert
							if (this.myTable.modal.data[field.name]) {
								this.myTable.modal.data[field.name] = this.$moment(this.myTable.modal.data[field.name]).format(this.$moment.HTML5_FMT.TIME);
							}
						}else if(fieldDetails.type == 'boolean' && fieldDetails.booleanOn !== undefined && fieldDetails.booleanOff !== undefined){
							if(!this.myTable.modal.data[field.name] || (this.myTable.modal.data[field.name] != fieldDetails.booleanOff && this.myTable.modal.data[field.name] != fieldDetails.booleanOn)){
								//default
								if(fieldDetails.default && (fieldDetails.default == fieldDetails.booleanOff || fieldDetails.default == fieldDetails.booleanOn)){
									// rewrite from this.$set
									this.myTable.modal.data[field.name] = fieldDetails.default;
								}else{
									// rewrite from this.$set
									this.myTable.modal.data[field.name] = false;
								}
							} else if (this.myTable.modal.data[field.name] == fieldDetails.booleanOn) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = true;
							} else if (this.myTable.modal.data[field.name] == fieldDetails.booleanOff) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = false;
							}
							field.type = fieldDetails.type;
							field.booleanOn = fieldDetails.booleanOn;
							field.booleanOff = fieldDetails.booleanOff;
						}else if(fieldDetails.type == 'textarea'){
							field.type = fieldDetails.type;
							//check for WYSIWYG editor
							if (fieldDetails.wysiwyg != undefined) {
								field.wysiwyg = fieldDetails.wysiwyg;
							}
							//default
							if (fieldDetails.default && !this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = fieldDetails.default;
							}
						}else if(fieldDetails.type == 'number'){
							field.type = fieldDetails.type;
							field.minimal_amount = fieldDetails.minimal_amount;
							// rewrite from this.$set
							this.myTable.modal.data[field.name] = parseFloat(this.myTable.modal.data[field.name]);
							//default
							if(fieldDetails.default && !this.myTable.modal.data[field.name]){
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = fieldDetails.default;
							}
						}else if(fieldDetails.type == 'password'){
							field.type = fieldDetails.type;
							// rewrite from this.$set
							this.myTable.modal.data[field.name] = '';
							//default
							if (fieldDetails.default && !this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = fieldDetails.default;
							}
						}else if(fieldDetails.type == 'enum' && fieldDetails.options && fieldDetails.options.length){
							field.type = fieldDetails.type;

							//default
							if (fieldDetails.default && !this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = fieldDetails.default;
							}
						}else if(fieldDetails.type == 'standard_file'){
							field.type = fieldDetails.type;
						}else if(fieldDetails.type == 'file'){
							field.type = fieldDetails.type;
							
							field.dropzoneOptions = {...this.dropzoneOptions};
							
							//check for delete URL
							if (fieldDetails.getFileUrl) {
								field.dropzoneOptions['getFileUrl'] = this.baseURL + fieldDetails.getFileUrl;
							}
							
							//check for upload URL
							if (fieldDetails.uploadFileUrl) {
								field.dropzoneOptions['uploadFileUrl'] = this.baseURL + fieldDetails.uploadFileUrl;
							}
							
							//check for delete URL
							if (fieldDetails.deleteFileUrl) {
								field.dropzoneOptions['deleteFileUrl'] = this.baseURL + fieldDetails.deleteFileUrl;
							}
							
							//check for dropzone description
							if (fieldDetails.dropzoneDescription) {
								field.dropzoneOptions['description'] = fieldDetails.dropzoneDescription;
							}
							
							//check for dropzone multiple
							if (fieldDetails.multiple) {
								field.dropzoneOptions['multiple'] = fieldDetails.multiple;
							}
							
							//check for dropzone param name
							if (fieldDetails.paramName) {
								field.dropzoneOptions['paramName'] = fieldDetails.paramName;
							}
							
							//check for dropzone disabled
							field.dropzoneOptions['disabled'] = this.checkDisabledModal(btn, 'field') || field.disabled;
							
							//default
							if (fieldDetails.default && !this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = fieldDetails.default;
							}
						}else if(fieldDetails.type == 'label'){
							field.type = fieldDetails.type;
						}else if (fieldDetails.type == 'combobox' && fieldDetails.inlineSearch){
							field.type = fieldDetails.type;
							field.inlineSearch = fieldDetails.inlineSearch;
							field.options = [];
						}else{
							field.type = 'text';
							//default
							if (fieldDetails.default && !this.myTable.modal.data[field.name]) {
								// rewrite from this.$set
								this.myTable.modal.data[field.name] = fieldDetails.default;
							}
						}
						
						//check for placeholder and set placeholder
						if (fieldDetails.placeholder) {
							field.placeholder = fieldDetails.placeholder;
						}
						
						//check for currency and set currency
						if (fieldDetails.currency) {
							field.currency = fieldDetails.currency;
						}
					}
				});

				this.myTable.modal.btn = btn;
				
				// write back process data request function
				this.myTable.processDataRequest = this.processDataRequest;
				
				// write back change field trigger function
				this.myTable.changeFieldTrigger = this.changeFieldTrigger;
				
				//check modal for required fields
				this.checkRequired();
				
				if (btn.wizard !== undefined && btn.wizard) {
					this.showWizard = true;
					this.myTable.showWizard = true;
				} else {
					this.showModal = true;
					this.myTable.showModal = true;
				}

				//parent function
				if(this.$parent['modalReady']){
					this.$parent['modalReady'](this.myTable.name);
				}
			} else {
				let copyRow = Object.assign({}, row);
				if(this.myTable.modal !== undefined && this.myTable.modal.fields !== undefined){
					this.myTable.modal.fields.forEach((field) => {
						switch(field.type) {
							case 'password':
								if(copyRow[field.name] == ''){
									delete copyRow[field.name];
								}
								break;
							case 'boolean':
								if (copyRow[field.name]) {
									copyRow[field.name] = field.booleanOn;
								}else{
									copyRow[field.name] = field.booleanOff;
								}
								break;
							case 'datetime':
								if(copyRow[field.name]){
									copyRow[field.name] = this.convertDateObjToJS(copyRow[field.name], true, field.type);
								}else{
									copyRow[field.name] = null;
								}
								break;
							case 'date':
								if(copyRow[field.name]){
									copyRow[field.name] = this.convertDateObjToJS(copyRow[field.name], true, field.type);
								}else{
									copyRow[field.name] = null;
								}
								break;
							case 'time':
								if(copyRow[field.name]){
									copyRow[field.name] = this.convertDateObjToJS(copyRow[field.name], true, field.type);
								}else{
									copyRow[field.name] = null;
								}
								break;
							default:
								break;
						}
					});
				} else if (this.myTable['fieldDetails'] !== undefined && typeof this.myTable['fieldDetails'] === 'object') {
					for (let fieldName in this.myTable['fieldDetails']) {
						let field = this.myTable['fieldDetails'][fieldName];
						switch(field.type) {
							case 'password':
								if(copyRow[field.name] == ''){
									delete copyRow[field.name];
								}
								break;
							case 'boolean':
								if (copyRow[field.name]) {
									copyRow[field.name] = field.booleanOn;
								}else{
									copyRow[field.name] = field.booleanOff;
								}
								break;
							case 'datetime':
								if(copyRow[field.name]){
									copyRow[field.name] = this.convertDateObjToJS(copyRow[field.name], true, field.type);
								}else{
									copyRow[field.name] = null;
								}
								break;
							case 'date':
								if(copyRow[field.name]){
									copyRow[field.name] = this.convertDateObjToJS(copyRow[field.name], true, field.type);
								}else{
									copyRow[field.name] = null;
								}
								break;
							case 'time':
								if(copyRow[field.name]){
									copyRow[field.name] = this.convertDateObjToJS(copyRow[field.name], true, field.type);
								}else{
									copyRow[field.name] = null;
								}
								break;
							default:
								break;
						}
					}
				}

				//btn actions
				switch(btn.action){
					case "deleteMany":
						//delete all
						this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.sending') : this.myTable.labels.deleteSending, 0);
						var postIds = '';
						this.myTable.selectedItems.forEach((selectedItem) => {
							postIds = postIds + selectedItem.id + '-';
						});
						this.req('GET', this.baseURL + '/' + this.myTable.controller + '/deleteMany/' + postIds).then((response) => {
							if(response.data.status){
								this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.ok') : this.myTable.labels.deleteOk);
								this.myTable.selectedItems.forEach((selectedItem) => {
									let searchRow = this.filterBy(this.myTable.data.rows, selectedItem.id, 'id');
									if(searchRow[0]){
										let searchRowIndex = this.myTable.data.rows.indexOf(searchRow[0]);
										this.myTable['data']['rows'].splice(searchRowIndex, 1);
										this.rows = this.myTable['data']['rows'];
									}
								});
							}else{
								if(response.data.message){
									this.showModalMessage(response.data.message, 0);
								}else{
									this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.not-ok') : this.myTable.labels.deleteNotOk, 0);
								}
							}
						}, (error) => {
							this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.error') : this.myTable.labels.deleteError, 0);
						});
						break;
					case "putCSV":
						//upload csv
						if(document.getElementById('csv_file').files[0]){
							this.showModalMessage('Sending CSV', 0);

							var data = new FormData();
							data.append("file", document.getElementById('csv_file').files[0]);
							
							let tmpCSVurl = this.myTable.controller + '/putCSV';
							if(this.myTable['putCSVurl']){
								tmpCSVurl = this.myTable['putCSVurl'];
							}
							
							this.req('POST', this.baseURL + '/' + tmpCSVurl, data).then((response) => {
								if (response.status) {
									response.data.forEach((dataRow) => {
										//insert or update
										if (dataRow.id) {
											let searchRow = this.filterBy(this.myTable.data.rows, dataRow.id, 'id');

											//update
											if (searchRow[0]) {
												let searchRowIndex = this.myTable.data.rows.indexOf(searchRow[0]);
												this.myTable['data']['rows'][searchRowIndex] = dataRow;
												this.rows = this.myTable['data']['rows'];
											} else {
												//insert
												this.myTable['data']['rows'].push(dataRow);
												this.rows = this.myTable['data']['rows'];
											}
											this.initiate();
										}
									});

									this.showModalMessage('CSV processed');
								} else {
									if (response.message) {
										this.showModalMessage(response.message, 0);
									} else {
										this.showModalMessage('CSV Not OK', 0);
									}
								}
							}, (error) => {
								this.showModalMessage('Error in fetching', 0);
							});
						}else{
							this.showModalMessage('No CSV selected', 0);
						}
						break;
					default:
						//other actions
						//send to server
						if(row.id){
							//delete or update
							if(btn.action == 'update'){
								//update
								this.showModalMessage(this.blnTranslation ? this.$t('crud-table.update.sending') : this.myTable.labels.updateSending, 0);
								let postId = copyRow.id;
								delete copyRow.id;

								this.req('POST', this.baseURL + '/' + this.myTable.controller + '/update/' + postId, copyRow).then((response) => {
									if (response.status) {
										this.showModalMessage(this.blnTranslation ? this.$t('crud-table.update.ok') : this.myTable.labels.updateOk, this.myTable['modal'] !== undefined && this.myTable['modal']['btn'] !== undefined && this.myTable['modal']['btn'].keepOpen ? 0 : undefined, false);
										
										//callback to parent
										if(this.$parent['afterChange'] !== undefined){
											this.$parent['afterChange'](this.myTable.name, response.data, 'UPDATE');
										}
										
										// update link table if needed
										let isLinkUpdate = this.updateLinkTable(row);
										
										let searchRow = this.rows.filter((filterRow) => { return filterRow['id'] == row.id; });
										if (searchRow[0]) {
											let searchRowIndex = this.rows.indexOf(searchRow[0]);
											
											// check if view is set
											if (this.myTable.view !== undefined && this.myTable.view) {
											
												// retrieve single row from backend
												let searchObject = {
													'controller': this.myTable.controller,
													'getId': postId,
												};
												if (this.myTable.function !== undefined && this.myTable.function) {
													searchObject['function'] = this.myTable.function;
												}
												if (this.myTable.afterChangeFunction !== undefined && this.myTable.afterChangeFunction) {
													searchObject['function'] = this.myTable.afterChangeFunction;
												}
												if (this.myTable.view !== undefined && this.myTable.view) {
													searchObject['view'] = this.myTable.view;
												}
												
												let originalResponseData = response.data;
												this.processDataRequest(searchObject).then((response) => {
													if (response.status) {
														// rewrite from this.$set
														this.rows[searchRowIndex] = row = response.data[0] !== undefined ? response.data[0] : response.data;
														this.myTable['data']['rows'] = this.rows;
													} else {
														// rewrite from this.$set
														this.rows[searchRowIndex] = originalResponseData;
														this.myTable['data']['rows'] = this.rows;
													}
												});
											} else {
												// rewrite from this.$set
												this.rows[searchRowIndex] = response.data;
												this.myTable['data']['rows'] = this.rows;
											}
											
										}
										
										if (this.myTable['modal'] !== undefined && this.myTable['modal']['btn']  !== undefined && this.myTable['modal']['btn'].keepOpen) {
											return;
										}
										
										if (!isLinkUpdate) {
											this.processSubFields(response.data.id);
										}
									}else{
										if(response.message){
											this.showModalMessage(response.message, 0);
										}else{
											this.showModalMessage(this.blnTranslation ? this.$t('crud-table.update.not-ok') : this.myTable.labels.updateNotOk, 0);
										}
									}
								}, (error) => {
									this.showModalMessage(this.blnTranslation ? this.$t('crud-table.update.error') : this.myTable.labels.updateError, 0);
								});
							} else if (btn.action == 'delete') {
								//delete
								this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.sending') : this.myTable.labels.deleteSending, 0);
								let postId = copyRow.id;
								delete copyRow.id;
								this.req('GET', this.baseURL + '/' + this.myTable.controller + '/delete/' + postId).then((response) => {
									if(response.status){
										let searchRow = this.filterBy(this.myTable.data.rows, postId, 'id');
										if(searchRow[0]){
											// update link table if needed
											copyRow.id = postId;
											for (let field in this.myTable.fieldDetails) {
												let details = this.myTable.fieldDetails[field];
												if (details.link !== undefined) {
													copyRow[field] = [];
												}
											}
											
											//callback to parent
											if(this.$parent['afterChange'] !== undefined){
												this.$parent['afterChange'](this.myTable.name, searchRow[0], 'DELETE');
											}
											
											this.updateLinkTable(copyRow);

											// delete row from rows
											let searchRowIndex = this.myTable.data.rows.indexOf(searchRow[0]);
											this.myTable['data']['rows'].splice(searchRowIndex, 1);
											this.rows = this.myTable['data']['rows'];
											
											this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.ok') : this.myTable.labels.deleteOk);

										}else{
											this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.not-ok') : this.myTable.labels.deleteNotOk, 0);
										}
									}else{
										if(response.message){
											this.showModalMessage(response.message, 0);
										}else{
											this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.not-ok') : this.myTable.labels.deleteNotOk, 0);
										}
									}
								}, (error) => {
									this.showModalMessage(this.blnTranslation ? this.$t('crud-table.delete.error') : this.myTable.labels.deleteError, 0);
								});
							}
						}else{
							//add
							this.showModalMessage(this.blnTranslation ? this.$t('crud-table.insert.sending') : this.myTable.labels.insertSending, 0);
							let postId = copyRow.id;
							delete copyRow.id;
							
							this.req('POST', this.baseURL + '/' + this.myTable.controller + '/insert', copyRow).then((response) => {
								if (response.status) {
									this.showModalMessage(this.blnTranslation ? this.$t('crud-table.insert.ok') : this.myTable.labels.insertOk);
									// check if row is already in data
									let searchRowIndex = this.myTable.data.rows.indexOf(row);
									if (searchRowIndex > -1) {
										this.myTable.data.rows[searchRowIndex] = response.data;
									} else {
										this.myTable.data.rows.push(response.data);
									}
									
									//callback to parent
									if (this.$parent['afterChange'] !== undefined) {
										this.$parent['afterChange'](this.myTable.name, response.data, 'CREATE');
									}

									// update link table if needed
									copyRow.id = response.data.id;
									let isLinkUpdate = this.updateLinkTable(copyRow, 'C');

									if (!isLinkUpdate) {
										this.processSubFields(response.data.id);
									}
									
									// check if view is set
									if (this.myTable.view !== undefined && this.myTable.view) {
									
										// retrieve single row from backend
										let searchObject = {
											'controller': this.myTable.controller,
											'getId': copyRow.id,
										};
										if (this.myTable.function !== undefined && this.myTable.function) {
											searchObject['function'] = this.myTable.function;
										}
										if (this.myTable.afterChangeFunction !== undefined && this.myTable.afterChangeFunction) {
											searchObject['function'] = this.myTable.afterChangeFunction;
										}
										if (this.myTable.view !== undefined && this.myTable.view) {
											searchObject['view'] = this.myTable.view;
										}
										
										let originalResponseData = response.data;
										this.processDataRequest(searchObject).then((response) => {
											if (response.status) {
												// rewrite from this.$set
												this.rows[searchRowIndex] = response.data[0] !== undefined ? response.data[0] : response.data;
												this.myTable['data']['rows'] = this.rows;
											} else {
												// rewrite from this.$set
												this.rows[searchRowIndex] = originalResponseData;
												this.myTable['data']['rows'] = this.rows;
											}
										});
									} else {
										// rewrite from this.$set
										this.rows[searchRowIndex] = response.data;
										this.myTable['data']['rows'] = this.rows;
									}

								}else{
									if (response.message) {
										this.showModalMessage(response.message, 0);
									} else {
										this.showModalMessage(this.blnTranslation ? this.$t('crud-table.insert.not-ok') : this.myTable.labels.insertNotOk, 0);
									}
								}
							}, (error) => {
								this.showModalMessage(this.blnTranslation ? this.$t('crud-table.insert.error') : this.myTable.labels.insertError, 0);
							});
						}
						break;
				}
			}
		},
		
		convertDateObjToJS: function(objDate, reverse, type, debug){
			if(debug){
				console.log('------- function Convert Date --------');
				console.log(objDate);
				console.log(type);
				console.log(reverse);
			}
			if(!reverse){
				let tempNewTime = '';
				if (objDate && typeof objDate === 'string') {
					let tempTime = objDate.split(/[- :]/);
					switch(type){
						case 'date':
							tempNewTime = new Date(tempTime[0], tempTime[1]-1, tempTime[2], 0, 0, 0);
							break;
						case 'time':
							if(!tempTime[2]){
								tempTime[2] = 0;
							}
							tempNewTime = new Date(0 ,0, 0, tempTime[0], tempTime[1], tempTime[2]);
							break;
						case 'datetime':
							tempNewTime = new Date(tempTime[0], tempTime[1]-1, tempTime[2], tempTime[3], tempTime[4], tempTime[5]);
							break;
					}
				}
				return tempNewTime;
			}else{
				switch(type){
					case 'date':
						objDate = new Date(objDate);
						if(typeof objDate.getFullYear !== 'function'){
							return null;
						}
						return ([objDate.getFullYear(), objDate.getMonth()+1, objDate.getDate()].join('-'));
					case 'time':
						objDate = new Date('1970-01-01 ' + objDate);
						if(typeof objDate.getHours !== 'function'){
							return null;
						}
						return ([objDate.getHours(), objDate.getMinutes(), objDate.getSeconds()].join(':'));
					case 'datetime':
						objDate = new Date(objDate);
						if(typeof objDate.getFullYear !== 'function'){
							return null;
						}
						return ([objDate.getFullYear(), objDate.getMonth()+1, objDate.getDate()].join('-')+' '+[objDate.getHours(), objDate.getMinutes(), objDate.getSeconds()].join(':'));
					default:
						return objDate;
				}
			}
		},

		// updates link table for row and returns true if no link table is found
		updateLinkTable: function(row, type) {
			if (type === undefined || !type) {
				type = 'U';
			}
			let copyRow = Object.assign({}, row);

			let postId = copyRow.id;
			delete copyRow.id;

			let updateCount = 0;

			// update link table
			if (this.myTable && this.myTable.fieldDetails) {
				for (let field in this.myTable.fieldDetails) {
					let details = this.myTable.fieldDetails[field];
					if (details.link !== undefined && copyRow[field] !== undefined) {
						let subController = details.link;
						let linkData = copyRow[field];
						delete copyRow[field];

						if (this.myTable.subQueries[subController] === undefined
							|| this.myTable.subQueries[this.myTable.fieldDetailsController[field]] === undefined) {
							return;
						}

						let linkQuery = this.myTable.subQueries[subController];
						let subQuery = this.myTable.subQueries[this.myTable.fieldDetailsController[field]];
						let originalRow = this.myTable.data.rows.filter(function(dataRow) { return dataRow.id == postId; });
						
						if ((originalRow && originalRow.length == 1 && ('original_' + field in originalRow[0])) || type == 'C') {
							// get old values and new values
							let oldValues = (type == 'C' || originalRow[0]['original_' + field] == undefined) ? [] : originalRow[0]['original_' + field];
							let newValues = linkData;
							
							// determine which need to be deleted and which need to be inserted
							let insertValues = this.arrDifference(newValues, oldValues);
							let deleteValues = this.arrDifference(oldValues, newValues);

							// get the deletion rows by the original data
							let deleteRows = this.myTable.data[subController].filter(function(dataRow) {
								return deleteValues.indexOf(dataRow[subQuery.ffield]) !== -1 && dataRow[linkQuery.ffield] == row[linkQuery.lfield];
							});

							// make the rows that needs to be inserted
							let insertRows = [];
							for (let key in insertValues) {
								let value = insertValues[key];
								
								let insertRow = {};
								insertRow[subQuery.ffield] = value;
								insertRow[linkQuery.ffield] = row[linkQuery.lfield];
								insertRows.push(insertRow);
							}

							// add to update count for clean update
							if (deleteRows && deleteRows.length) {
								updateCount += deleteRows.length;
							}

							// add to update count for clean update
							if (insertRows && insertRows.length) {
								updateCount += insertRows.length;
							}

							// check if the rows exists in the original data
							if (deleteRows && deleteRows.length) {
								// delete the rows by id from the link table
								for (let key in deleteRows) {
									let deleteRow = deleteRows[key];
									if (deleteRow.id) {
										this.req('GET', this.baseURL + '/' + subController + '/delete/' + deleteRow.id).then(function(response) {
											if (response.status) {
												let index = this.myTable.data[subController].indexOf(deleteRow);
												if (index !== -1) {
													this.myTable.data[subController].splice(index, 1);
												}
											}

											updateCount--;
											if (updateCount == 0) {
												this.processSubFields(postId);
											}
										});
									}
								}
							}

							// check if the rows are succesfully made
							if (insertRows && insertRows.length) {
								// insert the rows in the link table
								for (let key in insertRows) {
									let insertRow = insertRows[key];
									
									this.req('POST', this.baseURL + '/' + subController + '/insert', insertRow).then((response) => {
										if (response.status) {
											this.myTable.data[subController].push(response.data);
										}

										updateCount--;
										if (updateCount == 0) {
											this.processSubFields(postId);
										}
									});
								}
							}
						}
					}
				}
			}

			return updateCount !== 0;
		},

		restoreOriginals: function(row) {
			let copyRow = Object.assign({}, row);
			
			let strOriginal = 'original_';
			for (let fieldName in copyRow) {
				let fieldValue = row[fieldName];
				let strPos = -1;
				if (fieldName &&(strPos = fieldName.indexOf(strOriginal)) !== -1) {
					let subField = fieldName.substr(strPos + strOriginal.length);
					copyRow[subField] = fieldValue;
					delete copyRow[fieldName];
				}
			}
			
			return copyRow;
		},
		
		showEditField: function(fieldName) {
			if (this.myTable.fieldDetails && this.myTable.fieldDetails[fieldName] && this.myTable.fieldDetails[fieldName].hideEdit) {
				return false;
			} else {
				// check conditional visibility
				return true;
			}
		},
		
		getAllOptions: function() {
			if (!this.arrReturnOptions) {
				this.arrReturnOptions = {};
				if (this.myTable.fieldDetails) {
					let subFieldDetailKeys = Object.keys(this.myTable.fieldDetails);
					if (subFieldDetailKeys) {
						subFieldDetailKeys.forEach((field) => {
							if (this.myTable.fieldDetails[field] && this.myTable.fieldDetails[field].changeTo && this.myTable.fieldDetailsController) {
								let subController = '';
								let subControllerData = [];
								if ((subController = this.myTable.fieldDetailsController[field]) && (subControllerData = this.myTable.data[subController])) {
									this.arrReturnOptions[field] = [];
									//set default undefined option
									let objOption = {};
									objOption.id = null;
									objOption.label = this.blnTranslation ? this.$t('crud-table.select-label') : this.myTable.labels.selectLabel;
									this.arrReturnOptions[field][0] = objOption;
									//----continue
									let ffield = 'id';
									if (this.myTable.fieldDetails[field].changeToFfield !== undefined) {
										ffield = this.myTable.fieldDetails[field].changeToFfield;
									} else if (this.myTable.fieldDetails[field].link !== undefined && this.myTable.subQueries[subController]['lfield'] !== undefined) {
										ffield = this.myTable.subQueries[subController]['lfield'];
									} else if (this.myTable.subQueries[subController] && this.myTable.subQueries[subController]['ffield'] !== undefined) {
										ffield = this.myTable.subQueries[subController]['ffield'];
									}

									subControllerData.forEach((row) => {
										objOption = {};
										objOption.id = row[ffield];
										objOption.label = '';
										this.myTable.fieldDetails[field].changeTo.forEach((rplField) => {
											let strPos = -1;
											if((strPos = rplField.indexOf('.')) !== -1){
												let subField = rplField.substr(strPos + 1);
												if(row[subField] !== undefined){
													objOption.label = objOption.label + row[subField];
												}else{
													objOption.label = objOption.label + rplField;
												}
											}else{
												// no conversion possible
												objOption.label = objOption.label + rplField;
											}
										});
										
										if(objOption.label == ''){
											objOption.label = '(empty)';
										}
										//write back to options
										//this.arrReturnOptions[field][objOption.id] = objOption;
										this.arrReturnOptions[field].push(objOption);
									});
								}
							}
						});
					}
				}
			}
			
			return this.arrReturnOptions;
		},
		
		//----------[sort rows]----------//
		showSort: function(fieldName) {
			return (this.sortRows[fieldName] !== undefined);
		},
		showSortReverse:  function(fieldName) {
			if(this.sortRows[fieldName] && !this.sortRows[fieldName].asc){
				return true;
			}else{
				return false;
			}
		},
		setSortRows: function(fieldName, desc, initiate = false) {
			if(this.sortRows[fieldName] === undefined){
				let sortObj = {
					'name': fieldName,
					'asc': !desc,
				};
				// rewrite from this.$set
				this.sortRows[fieldName] = sortObj;
			}else if(desc !== undefined){
				this.sortRows[fieldName].asc = !desc;
			}else if(this.sortRows[fieldName].asc){
				this.sortRows[fieldName].asc = false;
			}else{
				delete this.sortRows[fieldName];
			}
			
			// search in backend
			if (this.myTable.backend !== undefined && this.myTable.backend && !initiate) {
				// after sort is complete, fetch the data
				this.getData();
			}
		},
		
		//----------[pagination]----------//
		setPage: function(key) {
			switch (key) {
				case '|<': 
					this.page = 0;
					break;
				case '<':
					this.page = (this.page - 1 > 0 ? this.page - 1 : 0);
					break;
				case '>': 
					this.page = (this.page + 1 < this.numberofpages - 1 ? this.page + 1 : this.numberofpages - 1);
					break;
				case '>|':
					this.page = this.numberofpages - 1;
					break;
				default:
					this.page = key - 1;
			}
		},
		getTotalRows: function(){
			let result = '';
			if(this.myTable.data === undefined || this.myTable.data.rows === undefined){
				return ;
			}
			if(this.cntFilteredItems){
				result = (this.myTable.data.rows.length - this.cntFilteredItems) + ' (' + this.myTable.data.rows.length + ')';
			}else{
				result = (this.myTable.data.rows.length - this.cntFilteredItems);
			}
			//total limit
			if(this.myTable.limit && this.myTable.limit < result){
				result = this.myTable.limit;
			}
			return result;
		},
		/*----------------[Filter]-----------------------*/
		getFilter: function(row) {
			let returnValue = true;
			
			for (let key in row) {
				let value = row[key];
				
				if (this.rowFilter[key] !== undefined && this.rowFilter[key] && this.rowFilter[key] != '!' && this.rowFilter[key] != '!!' && value) {
					let matchValue = value;
					if(this.myTable.fieldDetails && this.myTable.fieldDetails[key] &&  this.myTable.fieldDetails[key].type){
						if (this.myTable.fieldDetails[key].type == 'boolean' && this.myTable.fieldDetails[key].booleanOn !== undefined && this.myTable.fieldDetails[key].booleanOff !== undefined) {
							if (matchValue ==  this.myTable.fieldDetails[key].booleanOn) {
								matchValue = 'true';
							} else {
								matchValue = 'false';
							}
						}
						if(this.myTable.fieldDetails[key].type == 'datetime' || this.myTable.fieldDetails[key].type == 'date' || this.myTable.fieldDetails[key].type == 'time'){
							//types
							switch(this.myTable.fieldDetails[key].type){
								case 'datetime':
									if (this.myTable['fieldDetails'][key]['format'] !== undefined && this.myTable['fieldDetails'][key]['format']) {
										matchValue = this.$moment(matchValue).format(this.myTable['fieldDetails'][key]['format']);
									} else {
										matchValue = this.$moment(matchValue).format('YYYY-MM-DD HH:mm:ss');
									}
									break;
								case 'date':
									if (this.myTable['fieldDetails'][key]['format'] !== undefined && this.myTable['fieldDetails'][key]['format']) {
										matchValue = this.$moment(matchValue).format(this.myTable['fieldDetails'][key]['format']);
									} else {
										matchValue = this.$moment(matchValue).format('YYYY-MM-DD');
									}
									break;
								case 'time':
									if (this.myTable['fieldDetails'][key]['format'] !== undefined && this.myTable['fieldDetails'][key]['format']) {
										matchValue = this.$moment(matchValue).format(this.myTable['fieldDetails'][key]['format']);
									} else {
										matchValue = this.$moment(matchValue).format('HH:mm');
									}
									break;
							}
						}
					}
					matchValue = matchValue.toString().toLowerCase();
					let matchFilter = this.rowFilter[key].toString().toLowerCase();
					
					//if field details Strict
                    if (this.myTable.fieldDetails && this.myTable.fieldDetails[key] &&  this.myTable.fieldDetails[key].strict){
                        matchFilter = new RegExp('^' + matchFilter + '$');
                    }
					
					returnValue = matchValue.match(matchFilter) && returnValue;
				} else if (this.rowFilter[key] !== undefined  && this.rowFilter[key] == '!!' && !value) {
					returnValue = returnValue && true;
				} else if (this.rowFilter[key] !== undefined  && this.rowFilter[key] == '!!' && value) {
					returnValue = returnValue && false;
				} else if (this.rowFilter[key] !== undefined  && this.rowFilter[key] == '!' && value) {
					returnValue = returnValue && true;
				} else if (this.rowFilter[key] !== undefined  && this.rowFilter[key] && !value){
					returnValue = false;
				} else {
					returnValue = returnValue && true;
				}
				
				//initiate date for min and max
				var date = null;
				
				//min
				if (this.rowFilterMin[key] !== undefined && this.rowFilterMin[key] && value) {
					if(typeof value === 'object' && value instanceof Date){
						date = value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, '0') + "-" + (value.getDate()).toString().padStart(2, '0');
						returnValue = returnValue && (this.rowFilterMin[key].toString().replace('/-/g', '') <= date.toString().replace('/-/g', ''));
					}else{
						returnValue = returnValue && (parseFloat(this.rowFilterMin[key]) <= parseFloat(value));
					}
				}

				//max
				if (this.rowFilterMax[key] !== undefined && this.rowFilterMax[key] && value) {
					if(typeof value === 'object' && value instanceof Date){
						date = value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, '0') + "-" + (value.getDate()).toString().padStart(2, '0');
						returnValue = returnValue && (this.rowFilterMax[key].toString().replace('/-/g', '') >= date.toString().replace('/-/g', ''));
					}else{
						returnValue = returnValue && (parseFloat(this.rowFilterMax[key]) >= parseFloat(value));
					}
				
				}
			}

			//rowFilter
			if(row['sba_selected'] === undefined && this.rowFilter['sba_selected'] == "true"){
				returnValue = returnValue && false;
			} else if ((row['sba_selected'] == "false" || row['sba_selected'] === undefined) && this.rowFilter['sba_selected'] === false){
				returnValue = returnValue && true;
			}

			return returnValue;
		},
		isNumeric: function(val) {
			return Number(parseFloat(val)) == val;
		},
		getPageIndex: function() {
			if (this.myTable.pageLimit && this.page) {
				return this.myTable.pageLimit * this.page;
			} else {
				return 0;
			}
		},
		
		getStatistics: function(name, filteredItems) {
			let varResult = '';
			//get field Type
			if(this.myTable && this.myTable.fieldDetails && this.myTable.fieldDetails[name] && this.myTable.fieldDetails[name].type && this.myTable.fieldDetails[name].type != 'boolean' && this.myTable.fieldDetails[name].type != 'float' && this.myTable.fieldDetails[name].type != 'number'){
				switch(this.myTable.fieldDetails[name].type) {
					case 'time':
						var i = 0;
						var minutes = 0;
						for (let rowKey in filteredItems) {
							let row = filteredItems[rowKey];
							for (let key in row) {
								let value = row[key];
								if(key === name && value){
									minutes += value.getHours() * 60 + value.getMinutes();
								}
							}
						}
						var hours = Math.floor(minutes / 60);
						minutes = minutes - hours * 60;
						if(String(minutes).length == 1){
							minutes = '0' + minutes;
						}
						if(String(hours).length == 1){
							hours = '0' + hours;
						}
						varResult = hours + ':' + minutes;
						break;
					default:
						varResult = '';
				}
			}else{
				let sum = 0.0;
				for (let rowKey in filteredItems) {
					let row = filteredItems[rowKey];
					for (let key in row) {
						let value = row[key];
						if(key === name && value){
							sum = sum + parseFloat(value);
						}
					}
				}
				sum = (isNaN(sum) ? '' : sum);
				varResult = sum == 0.0 ? '' : sum;
			}
			this.myTable.statistics[name] = varResult;
			return varResult;
		},
		
		/*----------[field filter]------*/
		toLabel: function(fieldName) {
			if(this.myTable['fieldDetails'] !== undefined && this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName].label !== undefined){
				return this.myTable['fieldDetails'][fieldName].label;
			}else{
				return fieldName.split("_").join(" ");
			}
		},
		getFieldType: function(fieldName){
				if(this.myTable['fieldDetails'] !== undefined && this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName].type !== undefined){
					return this.myTable['fieldDetails'][fieldName].type;
				}else{
					return 'text';
				}
		},
		getFieldOption: function(fieldName, option){
			if(this.myTable['fieldDetails'] !== undefined && this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName][option] !== undefined){
				return this.myTable['fieldDetails'][fieldName][option];
			}else{
				return false;
			}
		},
		getIfBooleanField: function(fieldName) {
			if(this.myTable['fieldDetails'] !== undefined && this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName].type !== undefined && this.myTable['fieldDetails'][fieldName].type == 'boolean'){
				return true;
			}else{
				return false;
			}
		},
		showFields: function(fieldName) {
			if (this.myTable['fields'] !== undefined) {
				if(this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName].hideList){
					return false;
				}

				//field maybe to lowercase
				let fieldNameLowerCase = fieldName.toLowerCase();
				return (this.myTable.fields.indexOf(fieldName) !== -1 || this.myTable.fields.indexOf(fieldNameLowerCase) !== -1);
			}else{
				return false;
			}
		},
		isInlineField: function(fieldName) {
			return this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName].inlineField;
		},
		isInlineSearch: function(fieldName) {
			return this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName].inlineSearch;
		},
		getFieldData: function(row, fieldName) {
			//normal
			if (row[fieldName] !== undefined){
				let strReturn = row[fieldName];
				if (this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName]['type'] && row[fieldName]) {
					//types
					switch (this.myTable['fieldDetails'][fieldName]['type']) {
						case 'datetime':
							strReturn = this.$moment(row[fieldName]).format('YYYY-MM-DD HH:mm:ss');
							if (this.myTable['fieldDetails'][fieldName]['format'] !== undefined && this.myTable['fieldDetails'][fieldName]['format']) {
								strReturn = this.$moment(row[fieldName]).format(this.myTable['fieldDetails'][fieldName]['format']);
							}
							break;
						case 'date':
							strReturn = this.$moment(row[fieldName]).format('YYYY-MM-DD');
							if (this.myTable['fieldDetails'][fieldName]['format'] !== undefined && this.myTable['fieldDetails'][fieldName]['format']) {
								strReturn = this.$moment(row[fieldName]).format(this.myTable['fieldDetails'][fieldName]['format']);
							}
							break;
						case 'time':
							strReturn = this.$moment(row[fieldName]).format('HH:mm');
							if (this.myTable['fieldDetails'][fieldName]['format'] !== undefined && this.myTable['fieldDetails'][fieldName]['format']) {
								strReturn = this.$moment(row[fieldName]).format(this.myTable['fieldDetails'][fieldName]['format']);
							}
							break;
						case 'boolean':
							if (row[fieldName] == this.myTable['fieldDetails'][fieldName].booleanOn) {
								strReturn = '✔';
							} else if (row[fieldName] == this.myTable['fieldDetails'][fieldName].booleanOff) {
								strReturn = '✖';
							}
							break;
						case 'number':
							strReturn = parseFloat(row[fieldName]);
							if (isNaN(strReturn)) {
								strReturn = '';
							} else {
								let currAmount = parseFloat(row[fieldName]);
								if(this.myTable['fieldDetails'][fieldName].currency){
									currAmount = this.$options.filters.currency(currAmount, this.myTable['fieldDetails'][fieldName].currency);
								}
								strReturn = currAmount;
							}

							break;
						case 'enum':
							strReturn = this.filterBy(this.myTable.fieldDetails[fieldName].options, row[fieldName], 'id');

							if(strReturn[0]){
								strReturn = strReturn[0].name;
							}else{
								strReturn = row[fieldName];
							}
							break;
					}
				}
				//translate
				if (this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName]['translate'])  {
					if (typeof strReturn == 'string') {
						strReturn = this.$t(strReturn);
					}
				}	
				
				//check for goToLink
				if (this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName]['goToLink']) {
					
					//set parameter, on field value or on original if available
					let param = row[fieldName];
					if (row['original_' + fieldName] !== undefined) {
						param = row['original_' + fieldName];
					}
					
					//check if field requires the id
					if (this.myTable['fieldDetails'][fieldName]['goToLinkWithId'] !== undefined && this.myTable['fieldDetails'][fieldName]['goToLinkWithId']) {
						param = row['id'];
					}
					
					strReturn = "<a href=\"" + this.myTable['fieldDetails'][fieldName]['goToLink'] + param + "\">" + row[fieldName] + "</a>";
				}
				return strReturn; 
			}
		},
		getFilteredSubRow: function(subRow, ffield, ffieldData) {
			if (Array.isArray(ffieldData)) {
				return subRow[ffield] !== undefined && ffieldData.indexOf(subRow[ffield]) !== -1;
			}

			if (subRow[ffield] !== undefined && subRow[ffield] == ffieldData) {
				return true;
			} else {
				return false;
			}
		},
		
		//----------[JSON CALL]----------*/
		processSubFields: function(row_id) {
			//update?
			if(row_id !== undefined){
				this.myTable.loadData = this.myTable.data;
			}
			
			// backend?
			if (this.myTable.backend !== undefined && this.myTable.backend 
				&& this.myTable.loadData.fields === undefined 
				&& this.myTable.data !== undefined && this.myTable.data.fields !== undefined && Array.isArray(this.myTable.data.fields)) {
				this.myTable.loadData.fields = this.myTable.data.fields;
			}
			
			//loop all rows for replace
			let rowsToKeep = [];
			let arrFields = this.myTable.loadData.fields;
			//set field Controller, in case no rows
			if(!this.myTable.loadData.rows.length){
				if(this.myTable.fieldDetails){
					for (let field in this.myTable.fieldDetails) {
						let objField = this.myTable.fieldDetails[field];
						if (objField.changeTo !== undefined) {
							//convert changeTo to array if not
							if(!Array.isArray(objField.changeTo)){
								let tmpChangeTo = objField.changeTo;
								objField.changeTo = [];
								objField.changeTo.push(tmpChangeTo);
							}
							//process changeTo with current Controller
							let rplFieldValue = '';
							objField.changeTo.forEach((rplField) => {
								let strPos = -1;
								if((strPos = rplField.indexOf('.')) !== -1){
									let subController = rplField.substr(0,strPos);
									//write controller back to fieldDetails
									if(!this.myTable.fieldDetailsController){
										this.myTable.fieldDetailsController = [];
									}
									
									if(!this.myTable.fieldDetailsController[field]){
										this.myTable.fieldDetailsController[field] = subController;
									}
								}
							});
						}
					}
				}
			}
			
			// loop through rows
			for (let index in this.myTable.loadData.rows) {
				
				let row = this.myTable.loadData.rows[index];
				if (row_id != null && row_id != row.id) {
					continue;
				}	
				arrFields.forEach((objField) => {
					let field = objField.name;
					if (this.myTable.fieldDetails && this.myTable.fieldDetails[field] && this.myTable.fieldDetails[field].changeTo) {
						
						//convert changeTo to array if not
						if (!Array.isArray(this.myTable.fieldDetails[field].changeTo)) {
							let tmpChangeTo = this.myTable.fieldDetails[field].changeTo;
							this.myTable.fieldDetails[field].changeTo = [];
							this.myTable.fieldDetails[field].changeTo.push(tmpChangeTo);
						}
						//process changeTo with current Controller
						let rplFieldValue = '';
						let linkFieldData = false;
						this.myTable.fieldDetails[field].changeTo.forEach(function(rplField) {
							let strPos = -1;
							if ((strPos = rplField.indexOf('.')) !== -1) {
								let subController = rplField.substr(0, strPos);
								if (this.myTable.fieldDetails[field].link) {
									subController = this.myTable.fieldDetails[field].link;
								}
								
								if (this.myTable.subQueries && this.myTable.subQueries[subController]) {
									//write controller back to fieldDetails
									if(!this.myTable.fieldDetailsController){
										this.myTable.fieldDetailsController = [];
									}
									if(!this.myTable.fieldDetailsController[field]){
										this.myTable.fieldDetailsController[field] = rplField.substr(0, strPos);
									}
									let subField = rplField.substr(strPos + 1);
									let lfield = field; //local lookup field
									// link local field
									if (this.myTable.fieldDetails[field].link && this.myTable.subQueries[subController].lfield) {
										lfield = this.myTable.subQueries[subController].lfield;
									}
									let ffield = 'id';
									if(this.myTable.fieldDetails[field].changeToFfield !== undefined){
										ffield = this.myTable.fieldDetails[field].changeToFfield;
									}else if(this.myTable.subQueries[subController]['ffield'] !== undefined){
										ffield = this.myTable.subQueries[subController]['ffield'];
									}
									let ffieldData = row[lfield];
									// check if subcontroller exists in loaded data
									if (this.myTable.loadData[subController]) {
										
										// get subitems from the subcontroller
										let subItems = this.myTable.loadData[subController].filter(function(subRow){return this.getFilteredSubRow(subRow, ffield, ffieldData)}.bind(this));
										
										// if link subitems, get the linked items
										if (subItems && this.myTable.fieldDetails[field].link) {
											subController = rplField.substr(0, strPos);
											// check if other subcontroller exists
											if (this.myTable.subQueries[subController]) {
												let rplFieldPlaceholder = '';
												if (typeof rplFieldValue != "object") { 
													rplFieldPlaceholder = rplFieldValue; 
													rplFieldValue = {}; 
												}
												// get field data from linked table
												let arrlfielddata = subItems.map((a) => { return a[this.myTable.subQueries[subController]['ffield']]; });
												subItems = this.myTable.loadData[subController].filter((subRow) => {return this.getFilteredSubRow(subRow, this.myTable.subQueries[subController]['lfield'], arrlfielddata)});
												// loop through subitems to created the desired list items
												if (subItems) {
													linkFieldData = subItems.map((a) => { return a[this.myTable.subQueries[subController]['lfield']]; })
													subItems.map((a) => { return a[subField]; }).forEach(function(item, key) {
														if (rplFieldValue[key] === undefined) {
															rplFieldValue[key] = rplFieldPlaceholder;
														}
														rplFieldValue[key] = rplFieldValue[key] + item;
													});
												}
											} else {
												// no conversion possible
												if (typeof rplFieldValue == "object") {
													for (let key in rplFieldValue) {
														let item = rplFieldValue[key];
														rplFieldValue[key] = rplFieldValue[key] + rplField;
													}
												} else {
													rplFieldValue = rplFieldValue + rplField;
												}
											}
										// if no link, add to field value
										} else {
											if(subItems[0] !== undefined && subItems[0][subField] !== undefined && subItems[0][subField] !== null){
												rplFieldValue = rplFieldValue + subItems[0][subField];
											}
										}
									} else {
										// no conversion possible
										if (typeof rplFieldValue == "object") {
											for (let key in rplFieldValue) {
												let item = rplFieldValue[key];
												rplFieldValue[key] = rplFieldValue[key] + rplField;
											}
										} else {
											rplFieldValue = rplFieldValue + rplField;
										}
									}
								} else {
									// no conversion possible
									if (typeof rplFieldValue == "object") {
										for (let key in rplFieldValue) {
											let item = rplFieldValue[key];
											rplFieldValue[key] = rplFieldValue[key] + rplField;
										}
									} else {
										rplFieldValue = rplFieldValue + rplField;
									}
								}
							} else {
								// no conversion possible
								if (typeof rplFieldValue == "object") {
									for (let key in rplFieldValue) {
										let item = rplFieldValue[key];
										rplFieldValue[key] = rplFieldValue[key] + rplField;
									}
								} else {
									rplFieldValue = rplFieldValue + rplField;
								}
							}
						}.bind(this));
						
						//write back to row
						row['original_' + field] = linkFieldData ? linkFieldData : row[field];
						row[field] = (typeof rplFieldValue == "object") ? Object.values(rplFieldValue).join(', ') : rplFieldValue;
					}else if(row[field] === undefined){
						//set anyway for filter
						row[field] = null;
						//no changeTo and not set in row?
						let strPos = -1;
						if((strPos = field.indexOf('.')) !== -1 && this.myTable.subQueries){
							let subController = field.substr(0,strPos);
							let subField = field.substr(strPos + 1);
							let lfield = this.myTable.subQueries[subController]['lfield'];
							
							//subQueryProcessing
							if(this.myTable.loadData[subController] && lfield && row[lfield]){
								let ffield = 'id';
								if(this.myTable.subQueries[subController]['ffield'] !== undefined){
									ffield = this.myTable.subQueries[subController]['ffield'];
								}
								
								//if original field is replaced
								let ffieldData = '';
								if(row['original_' + lfield]){
									ffieldData = row['original_' + lfield];
								}else{
									ffieldData = row[lfield];
								}
								let subItems = this.myTable.loadData[subController].filter(function(subRow){
									return this.getFilteredSubRow(subRow, ffield, ffieldData)
								});
								if(subItems[0] !== undefined && subItems[0][subField] !== undefined){
									row[field] = subItems[0][subField];
								}
							}
						}
					}
					//----------[Convert Fields Types]----------//
					if(this.myTable.fieldDetails && this.myTable.fieldDetails[field] && this.myTable.fieldDetails[field].type){
						//datetime
						switch(this.myTable.fieldDetails[field].type){
							case 'datetime':
								if(row[field]){
									row[field] = this.convertDateObjToJS(row[field], false, 'datetime');
								}
								break;
							case 'date':
								if(row[field]){
									row[field] = this.convertDateObjToJS(row[field], false, 'date');
								}
								break;
							case 'time':
								if(row[field]){
									row[field] = this.convertDateObjToJS(row[field], false, 'time');
								}
								break;
						}
					}
					//----------[Where Fields]----------//
					if(this.myTable.fieldDetails && this.myTable.fieldDetails[field]){
						if (this.myTable.backend === undefined || !this.myTable.backend) {
							//sort
							if(this.myTable.fieldDetails[field].sortAsc !== undefined){
								let desc = !this.myTable.fieldDetails[field].sortAsc;
								if(this.myTable.fieldDetails[field].sortOriginal){
									this.setSortRows('original_' + field, desc, true);
								}else{
									this.setSortRows(field, desc, true);
								}
							}
						}
						//where
						if(this.myTable.fieldDetails[field].where){
							let tmpWhere = this.myTable.fieldDetails[field].where;
							//array of 2 parts
							if (typeof tmpWhere == "object") {
								for (let compare in tmpWhere) {
									let right = tmpWhere[compare];
									let left = '';
									if(!this.myTable.fieldDetails[field]['whereOnChangeTo'] && row['original_' + field]){
										left = row['original_' + field];
									}else{
										left = row[field];
									}
									if(!this.doCompare(left, compare, right)){
										rowsToKeep.push(row.id);
									}
								}
							}
						}
					}
				});
			}
			
			//inverse items to be deleted
			this.myTable.loadData.rows = this.myTable.loadData.rows.filter((row) => { return rowsToKeep.indexOf(row.id) === -1; });

			//copy loadData to data (decision if loadData is Object(1) of Array(2))
			// rewrite from this.$set
			this.myTable.data = this.myTable.loadData;
			
			this.myTable.loadData = {};
			
			// set reactive components for table view
			this.rows = this.myTable['data']['rows'];

			//predefined filters?
			if (this.myTable['predefinedFilters']) {
				this.rowFilter = this.myTable['predefinedFilters'];
			}

			//done?
			this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.ready') : this.myTable.labels.ready);
			if(this.$parent['tableReady']){
				this.$parent['tableReady'](this.myTable.name);
			}
			this.loading = false;
			this.currentTime = Date.now();
		},
		
		//----------[data]----------//
		getData: function() {
			// initiate search object
			let searchObject = {
				'controller': this.myTable.controller,
			};
			
			// set custom function
			if (this.myTable.function !== undefined) {
				searchObject.function = this.myTable.function;
			}
			
			// set custom get ID
			if (this.myTable.getId !== undefined && this.myTable.getId) {
				searchObject.getId = this.myTable.getId;
			}
			
			// set view
			if (this.myTable.view !== undefined && this.myTable.view) {
				searchObject.view = this.myTable.view;
			}
			
			// initiate default filters
			if (this.myTable.defaultFilters !== undefined && Array.isArray(this.myTable.defaultFilters)) {
				if (searchObject['filters'] === undefined) {
					searchObject['filters'] = [];
				}
			
				this.myTable.defaultFilters.forEach((defaultFilter) => {
					if (typeof defaultFilter === 'object' && defaultFilter.name && defaultFilter.value) {
						searchObject['filters'].push(defaultFilter);
					}
				});
			}
			
			// initiate backend filters
			if (this.myTable.backend !== undefined && this.myTable.backend) {
				// check row filter
				if (this.rowFilter !== undefined && typeof this.rowFilter === 'object') {
					if (searchObject['filters'] === undefined) {
						searchObject['filters'] = [];
					}
					
					// loop through rowFilter
					for (let fieldName in this.rowFilter) {
						// set filter value
						let filterValue = this.rowFilter[fieldName];
						
						// check boolean row filter
						if (this.myTable.fieldDetails[fieldName] !== undefined && this.myTable.fieldDetails[fieldName].type !== undefined && this.myTable.fieldDetails[fieldName].type == 'boolean' && this.myTable.fieldDetails[fieldName].booleanOn !== undefined && this.myTable.fieldDetails[fieldName].booleanOff !== undefined) {
							if (filterValue ===  'true') {
								filterValue = this.myTable.fieldDetails[fieldName].booleanOn;
							} else if (filterValue ===  'false') {
								filterValue = this.myTable.fieldDetails[fieldName].booleanOff;
							} else {
								filterValue = "";
							}
						}
						
						// add filter to searchObject
						let filter = {
							name: fieldName,
							value: filterValue,
						};
						searchObject['filters'].push(filter);
					}
				}
				
				// check row filter min
				if (this.rowFilterMin !== undefined && typeof this.rowFilterMin === 'object') {
					if (searchObject['minFilters'] === undefined) {
						searchObject['minFilters'] = [];
					}
					
					// loop through rowFilterMin
					for (let fieldName in this.rowFilterMin) {
						// add filter to searchObject
						let filter = {
							name: fieldName,
							value: this.rowFilterMin[fieldName],
							equal: true,
						};
						searchObject['minFilters'].push(filter);
					}
				}
				
				// check row filter max
				if (this.rowFilterMax !== undefined && typeof this.rowFilterMax === 'object') {
					if (searchObject['maxFilters'] === undefined) {
						searchObject['maxFilters'] = [];
					}
					
					// loop through rowFilterMin
					for (let fieldName in this.rowFilterMax) {
						// add filter to searchObject
						let filter = {
							name: fieldName,
							value: this.rowFilterMax[fieldName],
							equal: true,
						};
						searchObject['maxFilters'].push(filter);
					}
				}
				
				// check sort rows
				if (this.sortRows !== undefined && typeof this.sortRows === 'object') {
					if (searchObject['sort'] === undefined) {
						searchObject['sort'] = [];
					}
					
					// loop through sortRows
					for (let fieldName in this.sortRows) {
						// add sort to searchObject
						searchObject['sort'][fieldName] = this.sortRows[fieldName];
					}
					
				}
			}
			
			// set limit
			if (this.myTable.limit !== undefined && !isNaN(this.myTable.limit)) {
				searchObject['limit'] = this.myTable.limit;
			}
			
			if(this.myTable.preload && this.myTable.preload.data && this.myTable.preload.data.rows){
				this.myTable.loadData.rows = this.myTable.preload.data.rows;
				this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.alreadyLoaded') : this.myTable.labels.alreadyLoaded);
				this.getSubData();
			}else{
				this.showMessage((this.blnTranslation ? this.$t('crud-table.loading') : this.myTable.labels.loading) + ' ' + this.myTable.controller);
				this.processDataRequest(searchObject).then((response) => {
					if (response.status) {
						this.myTable.loadData.rows = response.data;
						this.getSubData();
					} else {
						this.myTable.loadData.rows = [];
						this.getSubData();
					}
				}, (response) => {
					this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.errorInFetching') : this.myTable.labels.errorInFetching);
					this.loading = false;
				});
			}
		},
		
		//--------------[subData]-----------------------
		getSubData: function(){
			if (!this.myTable.subQueries || (this.myTable.subQueries !== undefined && !Object.keys(this.myTable.subQueries).length)) {
				//process subfields
				this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.processSubFields') : this.myTable.labels.processSubFields,0);
				this.processSubFields();
				return;
			} else {
				this.cntSubQueriesDone = 0;
				for (let subQueryName in this.myTable.subQueries) {
					let subQuery = this.myTable.subQueries[subQueryName];
					let subQueryFunction = 'getAll';
					if (subQuery.function) {
						subQueryFunction = subQuery.function;
					}
					if(!subQuery.name){
						subQuery.name = subQuery.controller;
					}
					//preload name
					let subQueryPreloadName = subQueryName + "_" + subQueryFunction;
					if (subQuery.controller) {
						if (this.$parent.SbaCrudTablePreload && this.$parent.SbaCrudTablePreload.data && this.$parent.SbaCrudTablePreload[subQueryPreloadName]){
							this.myTable.loadData[subQueryName] = this.$parent.SbaCrudTablePreload[subQueryPreloadName];
							this.showMessage('SubQuery ' + subQuery.controller + ' ' + (this.blnTranslation ? this.$parent.$t('crud-table.alreadyLoaded') : this.myTable.labels.alreadyLoaded));
							this.cntSubQueriesDone++;
						} else {
							this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.loading') : this.myTable.labels.loading + ' ' + subQueryName + '...');
							
							// build searchObject
							let searchObject = {
								controller: subQuery.controller,
								function: subQueryFunction,
							}
							
							// perform backend
							if (subQuery.backend !== undefined && subQuery.backend) {
								// add searchObject
								searchObject['limit'] = (subQuery.limit !== undefined && !isNaN(subQuery.limit) ? subQuery.limit : 500);
							}
							
							// perform data request
							this.processDataRequest(searchObject).then((response) => {
								this.myTable.loadData[subQueryName] = [];
								if (response.status) {
									if (this.$parent.SbaCrudTablePreload === undefined) {
										this.$parent.SbaCrudTablePreload = {};
									}
									if (this.$parent.SbaCrudTablePreload.data === undefined) {
										this.$parent.SbaCrudTablePreload.data = {};
									}
									if (this.$parent.SbaCrudTablePreload.data[subQueryPreloadName] === undefined) {
										this.$parent.SbaCrudTablePreload.data[subQueryPreloadName] = response.data;
									}
									this.myTable.loadData[subQueryName] = response.data;
								}
								this.cntSubQueriesDone++;
							}, (error) => {
								this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.errorInFetching') : this.myTable['labels']['errorInFetching']);
								this.cntSubQueriesDone++;
							});
						}
					} else {
						this.cntSubQueriesDone++;
					}
				}
			}
		},
		
		//----------[initiate]-----*/
		initiate: function(automatic) {
			if (automatic === undefined) {
				automatic = false;
			}
			if(this.myTable !== undefined){
				if(!this.myTable.initiate && this.myTable.initiate !== undefined && automatic){
					return ;
				}
				//panel class
				if(this.myTable.panelClass){
					this.panelClass = this.myTable.panelClass;
				}
				
				this.rows = [];
				this.fields = [];
				
				this.myTable.showWizard = false;
				this.myTable.showModal = false;
				// rewrite from this.$set
				this.myTable.checkModalRequired = false;
				
				//data object for table
				this.myTable.statistics = {};
				this.myTable.data = {};
				this.myTable.data.rows = reactive([]);
				this.myTable.data.fields = [];
				this.myTable.selectedItems = [];
				this.myTable.convertDateObjToJS = function(objDate, reverse, type, debug){
					return this.convertDateObjToJS(objDate, reverse, type, debug);
				}
				this.myTable.toggleButton = (btn, row, blnSubmit) => {
					this.processBtns(btn, row, blnSubmit);
				}
				this.myTable.setCombobox = (fieldName, fieldId) => {
					if (fieldName !== undefined && this.combobox[fieldName] !== undefined && fieldId !== undefined && this.myTable.modal.data[fieldName] !== undefined) {
						let searchField = this.filterBy(this.myTable.modal.fields, fieldName, 'name');
						
						if(searchField[0] && searchField[0].options){
							let searchFieldOptions = this.filterBy(searchField[0].options, fieldId, 'id');
							if(searchFieldOptions[0]){
								this.myTable.modal.data[fieldName] = fieldId;
								this.combobox[fieldName] = searchFieldOptions[0].label;
							}else{
								this.myTable.modal.data[fieldName] = null;
								this.combobox[fieldName] = searchField[0].options[0].label;
							}
						}
					}
				}
				this.myTable.call = (strFunction) => {
					if (this[strFunction]) {
						this[strFunction]();
					}
				}
				/*----------------[Select Rows]------------------*/
				this.myTable.selectRows = (filteredRows) => {
					switch (this.myTable['toSelectRows']) {
						case 1:
							//deselect filtered rows
							filteredRows.forEach(function(row, index) {
								this[index]['sba_selected'] = false;
							}, filteredRows);
							break;
						case 2:
							//select filtered rows
							filteredRows.forEach(function(row, index) {
								this[index]['sba_selected'] = true;
							}, filteredRows);
							break;
						case 3:
							//de-select all rows
							this.myTable['data']['rows'].forEach(function(row, index) {
								this[index]['sba_selected'] = false;
							}, this.myTable['data']['rows']);
							this.rows = this.myTable['data']['rows'];
							break;
						case 4:
							//select all rows
							this.myTable['data']['rows'].forEach(function(row, index) {
								this[index]['sba_selected'] = true;
							}, this.myTable['data']['rows']);
							this.rows = this.myTable['data']['rows'];
							break;
					}
				}

				//showFilters
				if(this.myTable.showFilters !== undefined){
					this.showFilters = this.myTable.showFilters;
				}
				
				//show getCSV
				if(this.myTable.getCSV){
					this.showCSV = true;
				}
				
				//show putCSV
				if(this.myTable.putCSV){
					this.buttons['PUTCSV']['header'] = true;
				}
				
				//current user
				if(this.myTable.blnCurrentUser !== undefined){
					this.blnCurrentUser = this.myTable.blnCurrentUser;
				}

				//translation
				if(this.myTable.blnTranslation !== undefined){
					this.blnTranslation = this.myTable.blnTranslation;
				}else{
					this.myTable.blnTranslation = this.blnTranslation;
				}

				//until loading is done
				this.myTable.loadData = {};
				this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.loading') : this.myTable['labels']['loading'], 0);
				this.loading = true;
				//buttons
				if (this.myTable['buttonRow'] == undefined) {
					this.myTable['buttonRow'] = true;
					
					if(this.myTable['allowDeleteMany']){
						this.buttons['DA']['header'] = true;
					}
				}
				
				//setup custom buttons
				if (this.myTable['buttons']) {
					for (let key in this.myTable['buttons']) {
						let button = {};
						if (this.buttons[key] != undefined){
							button = this.buttons[key];
							Object.entries(this.myTable['buttons'][key]).forEach(entry => {
								const [key, value] = entry;
								if (key == 'id') {
									button[key] = value.toUpperCase();
								} else {
									button[key] = value;
								}
							})
						} else {
							button = this.myTable['buttons'][key];
						}
						// rewrite from this.$set
						this.buttons[button['id'].toUpperCase()] = button;
					}
					
					this.myTable['buttons'] = this.buttons;
				}

				//get table fields from controller
				let url = this.baseURL + '/' + this.myTable['controller'] + '/fields';
				
				// view is required for field select
				if (this.myTable.view !== undefined && this.myTable.view) { 
					let requestParameter = {select: this.myTable.view };
					url = url + '?q=' + JSON.stringify(requestParameter);
				}
				
				this.req('GET', url).then((response) => {
					let data = response;
					if(data.status){
						this.myTable.loadData.originalFields = data.data;
						//check if fields are already set
						if(this.myTable.fields !== undefined){
							this.myTable.loadData.fields = [];
							this.myTable.fields.forEach(function(value) {
								let objTemp = {
									'name': value,
								};
								this.myTable.loadData.fields.push(objTemp);
							}.bind(this));
							//auto list undefined fields
							if(this.myTable.listUndefinedFields){
								let tmpFilterFields = this.myTable.loadData.originalFields.filter((item) => {
									let tmpResult = false;
									if (item.name != 'id') {
										tmpResult = true;
										this.myTable.loadData.fields.forEach((field) => {
											if (item.name == field.name) {
												tmpResult = false;
											}
										});
									} else {
										tmpResult = false;
									}
									return tmpResult;
								});
								//put them into fields
								tmpFilterFields.forEach((field) => {
									this.myTable.loadData.fields.push(field);
									//set field details
									let tmpFieldDetail = {
										'hideList': true,
										'hideEdit': false,
									};
									if (this.myTable.fieldDetails === undefined) {
										this.myTable.fieldDetails = {};
									}
									this.myTable.fieldDetails[field.name] = tmpFieldDetail;
								});
							}
						}else{
							this.myTable.loadData.fields = this.myTable.loadData.originalFields;
							//set field names
							this.myTable.fields = [];
							this.myTable.loadData.fields.forEach(function(field) {
								this.myTable.fields.push(field.name);
							}.bind(this));
						}

						this.myTable.loadData.originalFields.forEach((field) => {
							//fieldDetails
							if(this.myTable.fieldDetails === undefined){
								this.myTable.fieldDetails = {};
							}
							if(this.myTable.fieldDetails[field.name] === undefined){
								this.myTable.fieldDetails[field.name] = {};
							}
							//tmpUpdateField
							let tmpUpdateField = this.filterBy(this.myTable.fields, field.name);
							if(this.myTable.listUndefinedFields || (tmpUpdateField && tmpUpdateField[0])){
								//------type------
								if(this.myTable.fieldDetails[field.name] && !this.myTable.fieldDetails[field.name].type){
									//auto set field type
									let blnOn = false;
									let blnOff = false;
									let strType = '';
									switch(field.type){
										case 'text':
											strType = 'textarea';
											break;
										case 'time':
											strType = 'time';
											break;
										case 'datetime':
											strType = 'datetime';
											break;
										case 'date':
											strType = 'date';
											break;
										case 'float':
											strType = 'number';
											break;
										case 'int':
											strType = 'number';
											if(field.max_length == 1){
												strType = 'boolean';
												blnOn = 1;
												blnOff = 0;
											}
											break;
										default:
											strType = 'text';
											break;
									}
									//set type
									let tmpFieldName = field.name;
									//no for _id
									if (tmpFieldName.indexOf('_id') === -1) {
										this.myTable.fieldDetails[field.name].type = strType;
									}

									if (blnOn !== false && blnOff !== false) {
										this.myTable.fieldDetails[field.name].booleanOn = blnOn;
										this.myTable.fieldDetails[field.name].booleanOff = blnOff;
									}
								}
							}
							//------max length------
							if(field.max_length){
								this.myTable.fieldDetails[field.name].max_length = field.max_length;
							}
							//------autoChangeTo----
							if(this.myTable.autoChangeTo && this.myTable.autoChangeTo.indexOf(field.name) !== -1){
								//auto changeTo
								let tmpFieldName = field.name;
								let tmpController = tmpFieldName.substr(0, tmpFieldName.lastIndexOf('_id'));
								let tmpChangeTo = tmpController + '.name';
								let tmpChangeToId = tmpController + '.id';
								if(this.myTable.fieldDetails[field.name].changeTo === undefined){
									this.myTable.fieldDetails[tmpFieldName].changeTo = [tmpChangeToId, ' - ', tmpChangeTo];
								}
								let arrSubQueries = {
									'lfield': tmpController + '_id',
									'ffield': 'id',
									'controller': tmpController,
								};
								if(this.myTable.subQueries === undefined){
									this.myTable.subQueries = {};
								}
								if(this.myTable.subQueries[tmpController] === undefined){
									this.myTable.subQueries[tmpController] = arrSubQueries;
								}
							}
							//-----sort-----
							if (this.myTable.backend !== undefined && this.myTable.backend 
								&& this.myTable.fieldDetails[field.name].sortAsc !== undefined) {
								let desc = !this.myTable.fieldDetails[field.name].sortAsc;
								if (this.myTable.fieldDetails[field.name].sortOriginal) {
									this.setSortRows('original_' + field.name, desc, true);
								} else {
									this.setSortRows(field.name, desc, true);
								}
							}
						});
						
						// after settings are complete, fetch the data
						this.getData();
					}
				}, function(response) {
					this.showMessage(this.blnTranslation ? this.$parent.$t('crud-table.errorInFetching') : this.myTable.labels.errorInFetching);
					this.loading = false;
				});
			}
		},
		getOrderedFields: function() {
			if (this.myTable['data'] === undefined || this.myTable['data']['fields'] === undefined) {
				return [];
			}
			return this.myTable['data']['fields'].filter((field) => {
				return this.showFields(field.name);
			}).slice().sort((a, b) => this.myTable['data']['fields'].indexOf(a.name.toLowerCase()) - this.myTable['data']['fields'].indexOf(b.name.toLowerCase()));
		},
		
		arrDifference(A, B) {
			let C = [];
			if (!Array.isArray(A) || !Array.isArray(B)) {
				return C;
			}

			A.forEach(function(key) {
				if (B.indexOf(key) === -1) {
					C.push(key);
				}
			});
			return C;
		},
		onFileChange: function (file, response, field) {
			//check for status
			if (response.status) {
				//check for multiple
				if (field['multiple'] && field['multiple'] == '1') {
					//set file in array
					if (this.myTable['modal']['data'][field.name] == undefined || this.myTable['modal']['data'][field.name].length <= 0 ) {
						// rewrite from this.$set
						this.myTable['modal']['data'][field.name] = [];
					}
					this.myTable['modal']['data'][field.name].push(response.data.file_name);
				} else {
					//set file_name in table
					// rewrite from this.$set
					this.myTable['modal']['data'][field.name] = response.data.file_name;
				}
			} else {
				//remove file from input
				this.$refs[field.name + '-input'][0].removeFile(file);
			}
		},
		onFileDeleted: function(file, field) {
			// remove file from external
			if (field.deleteFileUrl != undefined) {
				let fileName = file['name'];
				if (file['externalFileName'] !== undefined) {
					fileName = file['externalFileName'];
				}
				let url = process.env.VUE_APP_API_URL + field.deleteFileUrl + '/' + fileName;
				this.req('GET', url).then((response) => { 
					if (response.status) {
						//check for multiple
						if (this.myTable['modal']['data'][field.name] && typeof this.myTable['modal']['data'][field.name] == 'object') {
							//look for file in array
							for (let index in this.myTable['modal']['data'][field.name]) {
								//check on filename
								if (this.myTable['modal']['data'][field.name][index] == fileName) {
									delete this.myTable['modal']['data'][field.name][index];
								}
							}
						} else if (this.myTable['modal']['data'][field.name]) {
							// set null to update database
							// rewrite from this.$set
							this.myTable['modal']['data'][field.name] = null;
						}
					}
				});
			}
		},
		dropzoneTemplate:function () {
			return `
				<div class="dz-preview dz-file-preview mb-3">
					<div class="d-flex flex-row "> 
						<div class="p-0 w-30 position-relative">
							<div class="dz-error-mark"><span><i></i>  </span></div>
							<div class="dz-success-mark"><span><i></i></span></div>
							<div class="preview-container">
								<img data-dz-thumbnail class="img-thumbnail border-0" />
								<i class="simple-icon-doc preview-icon"></i>
							</div>
						</div>
						<div class="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
							<div> <span data-dz-name /> </div>
							<div class="text-primary text-extra-small" data-dz-size></div>
						</div>
						<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
						<div class="dz-error-message"><span data-dz-errormessage></span></div>
					</div>
					<a href="#" class="remove" data-dz-remove> <i class="glyph-icon simple-icon-trash"></i> </a>
				</div>
			`;
		}
	},
	created() {
		this.$moment = moment;
		
		if (!this.myTable) {
			console.log('sba-crud-table: my-table attribute needs to be defined');
			return;
		}
		if (!this.myTable.controller) {
			console.log('sba-crud-table: controller needs to be defined for my-table');
			return;
		}
		
		// check baseURL
		if (this.myTable['baseURL'] !== undefined) {
			this.baseURL = this.myTable['baseURL'];
		}
		
		//setup labels
		if (this.myTable['labels'] === undefined) {
			this.myTable['labels'] = {};
		}
		for (let lblName in this.labels) {
			let lblLabel = this.labels[lblName];
			if(this.myTable['labels'][lblName] === undefined){
				this.myTable['labels'][lblName] = lblLabel;
			}
		}
		
		// rewrite from this.$set
		this.myTable.globalSearch = (this.myTable.globalSearch === undefined ? '' :this.myTable.globalSearch);
		
		this.initiate(true);
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		}),
		headerButtons: function() {
			return Object.values(this.buttons).filter((btn) => {
				let controller = this.myTable['controller'];
				let status = false
				
				status = btn.header;
				
				//check for custom controller
				if (btn.controller !== undefined) {
					controller = btn.controller;
				}
				
				if (this.blnCurrentUser && this.currentUser && this.currentUser.rights && this.currentUser.rights[controller]  ) {
					status = status && this.currentUser.rights[controller][btn.function];
				}
				
				return status;
			});
		},
		inlineButtons: function() {
			return Object.values(this.buttons).filter((btn) => {
				let controller = this.myTable['controller'];
				let status = false
				
				status = btn.inline;
				
				//check for custom controller
				if (btn.controller !== undefined) {
					controller = btn.controller;
				}
				
				if (this.blnCurrentUser && this.currentUser && this.currentUser.rights && this.currentUser.rights[controller]  ) {
					status = status && this.currentUser.rights[controller][btn.function];
				}
				
				return status;
			});
		},
		orderedFields: function() {
			if (this.myTable['data']['fields'] === undefined) {
				return [];
			}
			return this.myTable['data']['fields'].slice().sort((a, b) => this.myTable['data']['fields'].indexOf(a.name.toLowerCase()) - this.myTable['data']['fields'].indexOf(b.name.toLowerCase()));
		},
		filteredRows: function() {
			// filter rows by page and page limit
			return this.limitBy(this.filterRows, this.myTable['pageLimit'], (this.myTable['pageLimit'] * this.page));
		},
		filterRows: function() {
			// filter rows calculation
			let filterRows = this.limitBy(this.sortedRows, this.myTable['limit']);
			// split global search for empty response
			if (this.myTable['globalSearch']) {
				filterRows = this.filterBy(filterRows, this.myTable['globalSearch']);
			}
			return this.filterBy(filterRows, this.getFilter);
		},
		cntFilteredItems: function() {
			// substract filter rows from rows
			return this.rows.length - this.filterRows.length;
		},
		numberofpages: function() {
			let cntResult = (this.rows.length - this.cntFilteredItems);
			if(this.myTable.limit && this.myTable.limit  < cntResult){
				cntResult = this.myTable.limit;
			}
			
			let pageLimit = 1;
			if(this.myTable['pageLimit']){
				pageLimit = this.myTable['pageLimit'];
			}
			
			return Math.ceil(cntResult / pageLimit);
		},
		amountOfPages: function() {
			let num = this.numberofpages;
			
			//max 10 pages
			let rtnArr = [];
			var i;
			var startI = 0;
			var addI = 5;
			if (this.page - 5 > 0) {
				startI = this.page - 5;
			} else {
				addI = (5 - this.page) + 5;
				if (addI + this.page > num) {
					addI = num - this.page;
				}
			}

			if (this.page + 5 > num) {
				addI = num - this.page;
			}

			//build pages
			if (startI + 5 <= num) {
				rtnArr.push('|<');
				rtnArr.push('<');
			}
			for (i = startI; i < this.page + addI; i++) {
				rtnArr.push(i+1);
			}
			if (startI + 5 <= num) {
				rtnArr.push('>');
				rtnArr.push('>|');
			}

			return rtnArr;
		},
		sortedRows: function() {
			let rows = [];
			let sortRowKeys = Object.keys(this.sortRows);
			if (sortRowKeys.length) {
				let tmpSortedRows = (this.myTable['data'] != undefined && Array.isArray(this.myTable['data']['rows'])) ? this.myTable['data']['rows'] : [];
				sortRowKeys.reverse();
				sortRowKeys.forEach((key) => {
					let orderRow = this.sortRows[key];
					tmpSortedRows.sort((a, b) => {
						if (a[orderRow.name] == b[orderRow.name]) { return 0; }
						if (orderRow.asc) {
							if (isNaN(a[orderRow.name]) || isNaN(b[orderRow.name])) {
								// string comparison asc
								return String(a[orderRow.name]).localeCompare(String(b[orderRow.name]), undefined, { sensitivity: 'base' });
							} else {
								return (a[orderRow.name] - b[orderRow.name]);
							}
						} else {
							if (isNaN(a[orderRow.name]) || isNaN(b[orderRow.name])) {
								// string comparison desc
								return String(b[orderRow.name]).localeCompare(String(a[orderRow.name]), undefined, { sensitivity: 'base' });
							} else {
								return (b[orderRow.name] - a[orderRow.name]);
							}
						}
					});
				});
				rows = tmpSortedRows;
			} else {
				rows = (this.myTable['data'] != undefined && Array.isArray(this.myTable['data']['rows'])) ? this.myTable['data']['rows'] : [];
			}
			
			return rows;
		},
		selectedRows: function() {
			let tmpRows = this.myTable['data'] !== undefined && this.myTable['data']['rows'] !== undefined && Array.isArray(this.myTable['data']['rows']) ? this.myTable['data']['rows'] : [];
			return this.filterBy(tmpRows, true, 'sba_selected');
		},
	}
}
</script>

<style scoped>
	input.sba-full {
		margin:		0;
		/*height:		100%;*/
		display:	inline-block;
		width:		100%;
		padding:	0;
	}
	input.sba-half {
		margin:		0;
		display:	inline-block;
		width:		50%;
		padding:	0;
		float:		left;
	}
	select.sba-options-full {
		width:		100% !important;
	}
	table.crud-table th{
		text-transform: capitalize;
	}
	div.crud-table-panel{
		height: 34px;
	}
	div.crud-table-footer-statistics{
		font-size: 11px;
		float:left;
	}
	input.crud-table-checkbox{
		margin: 10px 0px;
	}
	div.crud-table-panel-text{
		float:right;
		font-size:11px;
	}
	tr.crud-table-statistics td{
		font-weight: bold;
	}
	tr.crud-table-statistics{
		border-top: 2px solid #ddd;

	}
	span.crud-table-message{
		padding:2px;
		border-radius:2px;
		margin:0px;
		background:green;
		color: white;
		float: right;
		font-size: 10px;
	}
	div.crud-table-modal-message{
		padding:2px;
		border-radius:2px;
		margin:0px;
		color: white;
		text-align: center;
		font-size: 16px;
	}
	.crud-table-bg-red{
		background: red;
	}
	.crud-table-bg-green{
		background: green;
	}
	span.crud-table-refresh{
		border: 1px solid grey;
		padding: 2px;
		margin-left: 10px;
	}
	span.crud-table-refresh:hover{
		border: 1px solid grey;
		padding: 2px;
		font-weight: bold;
		cursor: pointer;
	}
	div.panel-heading{
		font-weight: bold;
	}
	div.crud-table-pagination{
		padding: 2px;
		text-align:	center;
	}
	div.crud-table-pagination ul{
		margin: 0px;
		flex-wrap: wrap;
	}
	.crud-table-right{
		float:right;
	}
	.crud-table-left{
		float:left;
	}
	.crud-table-divide-before{
		border-left: 1px solid #cecece;
		padding-left: 6px;
		padding-right: 6px;
	}
	.crud-table-spacing-after{
		padding-right: 6px;
	}
	input.crud-table-search{
		font-size: 10px;
		margin-left: 4px;
		color: black;
		margin-right: 6px;
	}
	.crud-table-sortorder:after {
		content: '\25b2';
	}
	.crud-table-sortorder.reverse:after {
		content: '\25bc';
	}
	th.crud-table-th:hover{
		cursor: pointer;
	}
	td.crud-table-button{
		display: table-cell !important;
	}
	button.crud-table-button-width{
		width: auto !important;
	}
	td.crud-table-button:hover{
		color: red;
		cursor: pointer;
	}
	div.crud-table-button-row{
		padding: 2px;
		color: #333;
		background-color: #f5f5f5;
		border-color: #ddd;
	}
	button.crud-table-button:hover{
		cursor: pointer;
	}
	button.crud-table-button{
		margin: 2px;
		width: 28px;
		height: 28px;
		position: relative;
		display: inline-block;
	}
	
	.crud-table-button:hover .tooltipcontainer .tooltiptext {
		visibility: visible;
		opacity: 1;
		width: 120px;
	}
	
	.crud-table-button:hover .tooltipcontainer {
		visibility: visible;
		opacity: 1;
		width: 130px;
	}
	
	.crud-table-button .tooltipcontainer {
		width: 0;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -120px;
		opacity: 0;
	}
	
	.crud-table-button .tooltipcontainer .tooltiptext {
		padding-top: 4px;
		display: inline-block;
		visibility: hidden;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 0;
		max-width: 120px;
		opacity: 0;
	}

	.crud-table-button .tooltipcontainer::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 90%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}
	
	.crud-table-modal {
		display: block;
		background: #cecece;
		background: rgba(240,240,240,0.8);
	}
	.crud-table-modal-body:before,.crud-table-modal-body:after {
		display: table;
		content: " ";
	}
	/*
	.crud-table-modal-header:before,.crud-table-modal-header:after {
		display: table;
		content: " ";
	}
	*/
	th.crud-table-shrink, td.crud-table-shrink{
		white-space: nowrap;
		width: 50px;
	}
	div.crud-table-modal-th{
		padding: 5px;
		padding-top: 7px;
		text-align: right;
		font-weight: bold;
		text-transform: capitalize;
	}
	.crud-table-move-left {
		width: auto;
		box-shadow: none;
	}
	.crud-table-input-icon{
		float: right;
		margin-right: 35px;
		margin-top: -30px;
		position: relative;
		z-index: 2;
		color: red;
	}
	.crud-table-input-icon-delete{
		float: right;
		margin-right: 52px;
		margin-top: -30px;
		position: relative;
		z-index: 2;
		color: gray;
	}
	.crud-table-input-icon-green{
		color: green;
	}
	.crud-table-modal-scroll{
		overflow-y: auto;
	}
	tr.crud-table-filter-hide{
		display: none;
	}
	tr.crud-table-filter-show{
		display: block;
	}
	.crud-table-btn-active{
		color: red;
	}
	@media (min-width: 1000px){
		.crud-table-modal-dialog {
			width: 850px;
		}
		.crud-table-modal-th{
			width: 20%;
		}
		.crud-table-modal-tв{
			width: 70%;
		}
	}
	@media only screen and (max-width: 800px) {
		/* Force table to not be like tables anymore */
		#crud-table-no-more-tables table,
		#crud-table-no-more-tables thead,
		#crud-table-no-more-tables tfoot,
		#crud-table-no-more-tables tbody,
		#crud-table-no-more-tables th,
		#crud-table-no-more-tables td,
		#crud-table-no-more-tables tr {
			display: block;
		}

		#crud-table-no-more-tables table {
			empty-cells: show;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		#crud-table-no-more-tables thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#crud-table-no-more-tables tr { border: 1px solid #ccc; }

		#crud-table-no-more-tables td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
			white-space: normal;
			text-align:left;
			word-wrap: break-word;
			min-height: 51px;
		}

		#crud-table-no-more-tables td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0px;
			left: 6px;
			width: 45%;
			/*padding-right: 10px; */
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			text-transform: capitalize;
			padding: 16px 10px;
		}

		/*
		Label the data
		*/
		#crud-table-no-more-tables td:before { content: attr(data-title); }

		#crud-table-empty{
			display:none !important;
		}
		button.crud-table-button, button.crud-table-button-header {
			border-radius: 0px;
			-webkit-appearance: none;
		}
		td.crud-table-shrink{
			width: auto;
		}
	}
	span.sba-multiselect-box .remove {
		cursor: pointer;
	}
	span.sba-multiselect-box {
		display: inline-block;
		padding: 4px;
		background-color: lightgrey;
		border-radius: 2px;
		margin: 4px 4px;
		border: 1px solid grey;
	}
	.readonlyWysiwyg{
		background-color: #e9ecef;
		margin: 0;
		padding: 10px;
		border: 1px solid #d7d7d7;
	}
	
	.inline-search-table table>tbody>tr:hover {
		background-color: rgb(228, 228, 228) !important;
		cursor: pointer;
	}
	
</style>