<template>
	<div>
		<div class="card mb-2" v-for="(card, index) in layout" :key="'card_' + index">
			<h5 class="card-header" v-if="card['card-header']" data-toggle="collapse" :href="'#card_' + index">
				{{ card['card-header']['name'] }}
				<i class="glyph-icon simple-icon-arrow-down float-right"></i>
			</h5>
			<div :id="'card_' + index" class="card-body collapse show" v-if="card['card-body']">
				<template v-for="(wizardLayout, layoutType, layoutIndex) in card['card-body']" :key="'card_' + index + '_layout_' + layoutIndex">
					<sba-crud-table-fields v-if="layoutType == 'fields'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-fields>
					<sba-crud-table-cards v-if="layoutType == 'cards'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-cards>
					<sba-crud-table-tabs v-if="layoutType == 'tabs'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-tabs>
					<sba-crud-table-rows v-if="layoutType == 'rows'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-rows>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	name: 'SbaCrudTableCards',
	props: ['myTable', 'layout'],
	components: { 
		'sba-crud-table-fields': defineAsyncComponent(() => import('./Fields.vue')), 
		'sba-crud-table-cards': defineAsyncComponent(() => import('./Cards.vue')), 
		'sba-crud-table-tabs': defineAsyncComponent(() => import('./Tabs.vue')), 
		'sba-crud-table-rows': defineAsyncComponent(() => import('./Rows.vue')), 
	},
	data() {
		return {}
	},
	methods: { }
};
</script>
<style scoped>
.card-header {
	border-bottom: 1px solid rgba(0,0,0,.125);
}
[data-toggle="collapse"] .glyph-icon:before {   
  content: "\e604";
}

[data-toggle="collapse"].collapsed .glyph-icon:before {
  content: "\e607";
}
</style>