import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/overview',
		//beforeEnter: AuthRequired,
		children: [
			{
				name: 'overview',
				path: 'page/app/overview',
				component: () => import(
					'./views/app/dashboard'
				),
			},
			{
				name: 'schedule',
				path: '/page/app/schedule',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/schedule')
			},
			{
				name: 'logbook',
				path: '/page/app/logbook',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/logbook')
			},
			{
				name: 'invoices',
				path: '/page/app/invoices',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoices')
			},
			{
				name: 'reporting',
				path: '/page/app/reporting',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/reporting')
			},
			{
				name: 'invoice',
				alias: '/#/invoice/:id',
				path: '/page/app/invoice/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoices/chat')
			},
			{
				name: 'informer',
				path: '/page/app/informer',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/informer')
			},
			{
				name: 'setup',
				path: '/page/app/setup',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/setup')
			},
			{
				name: 'users',
				path: '/page/app/users',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/users')
			},
			{
				name: 'database',
				path: '/page/app/database',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/database')
			},
			{
				name: 'git',
				path: '/page/app/git',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/git')
			},
			{
				name: 'admin',
				path: '/page/app/admin',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/admin')
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ path: 'login/:branding', component: () => import('./views/public/user/Login')  },
			{ path: 'login/', component: () => import('./views/public/user/Login')  },
			{ path: 'register', component: () => import('./views/public/user/Register') },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'register/:token', component: () => import('./views/public/user-register') }
		],

	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
